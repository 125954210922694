import * as React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { useServerError } from '../../hooks/useServerError';

export const ServerErrorMessage = () => {
  const { isServerError, isNetworkError } = useServerError();

  return (
    <>
      {(isServerError || isNetworkError) && (
        <div style={{ marginBottom: 16 }}>
          <ErrorMessage>
            {isNetworkError ? (
              <>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                It seems there's a problem with the internet connection, please{' '}
                <a href="/">reload the page</a> and try again later.
              </>
            ) : (
              <>
                An error occurred, please <a href="/">reload the page</a> and
                try again later.
              </>
            )}
          </ErrorMessage>
        </div>
      )}
    </>
  );
};
