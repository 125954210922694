import React, { FC } from 'react';
import { Task } from '../../types/task';
import { amber, lightBlue, lightGreen } from '@material-ui/core/colors';
import { ColoredBadge } from '../../components/ColoredBadge';
import { nbsp } from '../../services/strings/strings';

interface StatusData {
  name: string;
  color: string;
}

const getStatusData = (status: Task['status']): StatusData => {
  const statusMap = {
    ready: { name: 'Ready', color: lightBlue[500] },
    // eslint-disable-next-line @typescript-eslint/camelcase
    in_progress: { name: 'In progress', color: amber[500] },
    done: { name: 'Done', color: lightGreen[500] },
  };

  return statusMap[status];
};

interface Props {
  status: Task['status'];
}

export const TaskStatusBadge: FC<Props> = ({ status }) => {
  const { name, color } = getStatusData(status);

  return (
    <ColoredBadge
      color={color}
      content={
        <b
          dangerouslySetInnerHTML={{
            __html: nbsp(name),
          }}
        />
      }
    />
  );
};
