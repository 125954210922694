import { useState } from 'react';
import { createLocalStorage } from '../../../services/localStorage/createLocalStorage';

export const useTaskListLayout = () => {
  const ls = createLocalStorage('taskListLayout');
  const state = ls.getItem();

  const [columnLayout, setColumnLayout] = useState(
    !!state ? JSON.parse(state) : false
  );

  const toggleColumnLayout = (state: boolean) => {
    setColumnLayout(state);
    ls.setItem(JSON.stringify(state));
  };

  return {
    columnLayout,
    toggleColumnLayout,
  };
};
