import { Method } from 'axios';
import { Action } from 'redux';
import { API_REQUEST_TYPE, ApiAction } from '../../types/api';

export const createApiAction = <D extends object>(
  url: string,
  actionPrefix: string,
  method: Method,
  options?: {
    data?: object;
    onSuccess?: Array<(data: D) => Action>;
    headers?: object;
    applyAuthToken?: boolean;
  }
): ApiAction<D> => ({
  type: API_REQUEST_TYPE,
  payload: {
    url,
    actionPrefix,
    method,
    applyAuthToken: options?.applyAuthToken,
    data: options?.data,
    onSuccess: options?.onSuccess,
    headers: options?.headers,
  },
});
