import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { removeTask, TaskRemoveAction } from '../actions/taskRemove';

type TaskRemoveState = ApiSingleData<undefined>;
const initialState: TaskRemoveState = createDefaultSingleApiData();

export const taskRemove = (
  state: TaskRemoveState = initialState,
  action: TaskRemoveAction
): TaskRemoveState => {
  switch (action.type) {
    case getType(removeTask.request):
      return {
        ...state,
        loading: true,
      };

    case getType(removeTask.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(removeTask.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
