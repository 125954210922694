import React, { FC } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CenteredText } from '../CenteredText';
import { StyledButton } from '../StyledButton';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

interface Props {
  isOpen: boolean;
  title: string;
  isLoading?: boolean;
  closeButton?: boolean;
  onDialogClose: () => void;
}

export const ContentDialog: FC<Props> = ({
  onDialogClose,
  isOpen,
  title,
  children,
  isLoading,
  closeButton,
}) => (
  <Dialog
    open={isOpen}
    onClose={onDialogClose}
    onBackdropClick={onDialogClose}
    aria-labelledby="form-dialog-title"
    fullWidth
    maxWidth="lg"
  >
    <div>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">{title}</Typography>
        <CloseButton aria-label="close" onClick={onDialogClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      {isLoading ? (
        <CenteredText style={{ padding: '32px 0' }}>
          <CircularProgress size={40} />
        </CenteredText>
      ) : (
        <>
          {children}
          {closeButton && (
            <DialogActions>
              <StyledButton onClick={onDialogClose}>Close</StyledButton>
            </DialogActions>
          )}
        </>
      )}
    </div>
  </Dialog>
);
