import * as React from 'react';
import { FormTextField } from '../../components/form/FormTextField';
import { MIN_PASSWORD_LENGTH } from '../../constants';
import { StyledButton } from '../../components/StyledButton';
import { CenteredText } from '../../components/CenteredText';
import { StyledLink } from '../../components/StyledLink';
import { routes } from '../../routes/routes';
import { useForm } from 'react-hook-form';
import { validationEmail, validationRequired } from '../../services/form/form';

const userNameError = (message: string) => ({
  username: {
    type: 'required',
    message,
  },
});

interface FormData {
  name: string;
  username: string;
  password: string;
  passwordConfirmation: string;
}

interface Props {
  loading: boolean;
  emailExistsError?: boolean;
  onSubmit: (formData: FormData) => void;
}

export const SignupForm = ({ loading, emailExistsError, onSubmit }: Props) => {
  const { register, errors, handleSubmit, watch } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        label="E-mail"
        name="username"
        style={{ marginBottom: 16 }}
        fullWidth
        register={register}
        errors={{
          ...errors,
          ...(emailExistsError
            ? userNameError(
                'This e-mail already exists, please use another one.'
              )
            : {}),
        }}
        validationOptions={{
          ...validationRequired(),
          ...validationEmail(),
        }}
      />
      <FormTextField
        label="Your name"
        name="name"
        style={{ marginBottom: 16 }}
        fullWidth
        register={register}
        errors={errors}
        validationOptions={{ ...validationRequired() }}
      />
      <FormTextField
        label="Password"
        type="password"
        name="password"
        style={{ marginBottom: 16 }}
        fullWidth
        register={register}
        errors={errors}
        validationOptions={{
          ...validationRequired(),
          minLength: {
            value: MIN_PASSWORD_LENGTH,
            message: `Password must contain at least ${MIN_PASSWORD_LENGTH} characters`,
          },
        }}
      />
      <FormTextField
        label="Password confirmation"
        type="password"
        name="passwordConfirmation"
        style={{ marginBottom: 32 }}
        fullWidth
        register={register}
        errors={errors}
        validationOptions={{
          ...validationRequired(),
          validate: (value: string) =>
            value !== watch('password') ? 'Passwords do not match' : true,
        }}
      />
      <StyledButton
        type="submit"
        style={{ marginBottom: 32 }}
        fullWidth
        isSubmitting={loading}
      >
        Sign Up
      </StyledButton>

      <CenteredText>
        <StyledLink to={routes.login}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Already have an account? Let's login
        </StyledLink>
      </CenteredText>
    </form>
  );
};
