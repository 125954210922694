import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Category } from '../../types/category';
import {
  updateCategory,
  CategoryUpdateAction,
} from '../actions/categoryUpdate';

type CategoryUpdateState = ApiSingleData<Category>;
const initialState: CategoryUpdateState = createDefaultSingleApiData();

export const categoryUpdate = (
  state: CategoryUpdateState = initialState,
  action: CategoryUpdateAction
): CategoryUpdateState => {
  switch (action.type) {
    case getType(updateCategory.request):
      return {
        ...state,
        loading: true,
      };

    case getType(updateCategory.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(updateCategory.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
