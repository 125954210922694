import React from 'react';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  Group as GroupIcon,
} from '@material-ui/icons';
import { routes } from '../../../routes/routes';
import { StyledLink } from '../../../components/StyledLink';
import { gridColumn } from '../../../services/dataGrid/dataGrid';
import { DataGridColumn } from '../../../types/dataGrid';
import { Project } from '../../../types/project';
import { User } from '../../../types/user';
import { UserNameAvatar } from '../../../components/UserNameAvatar';
import { Tooltip } from '@material-ui/core';

export const createProjectsGrid = (
  currentUser: User | null,
  onProjectEdit: (uuid: string) => void,
  onProjectRemove: (uuid: string) => void,
  onInvitationSend: (uuid: string) => void,
  onInvitationListOpen: (uuid: string) => void
): DataGridColumn<Project>[] => [
  gridColumn('name', 'Name', (row) => (
    <StyledLink to={routes.tasks(row.uuid)}>{row.name}</StyledLink>
  )),
  gridColumn('author', 'Author', ({ user }) => (
    <UserNameAvatar username={user.name} color={user.avatarColor} />
  )),
  gridColumn('actions', 'Actions', (row) => {
    const isUserProjectOwner = row.user.uuid === currentUser?.uuid;

    return (
      <>
        {isUserProjectOwner && (
          <>
            <Tooltip title="Invite a user to the project">
              <MailIcon
                color="primary"
                style={{
                  cursor: 'pointer',
                  marginRight: 8,
                }}
                onClick={() => {
                  onInvitationSend(row.uuid);
                }}
              />
            </Tooltip>
            <Tooltip title="Invited and assigned users to the project">
              <GroupIcon
                color="primary"
                style={{
                  cursor: 'pointer',
                  marginRight: 8,
                }}
                onClick={() => {
                  onInvitationListOpen(row.uuid);
                }}
              />
            </Tooltip>
          </>
        )}
        <Tooltip title="Edit project">
          <EditIcon
            color="primary"
            style={{
              cursor: 'pointer',
              marginRight: 8,
            }}
            onClick={() => {
              onProjectEdit(row.uuid);
            }}
          />
        </Tooltip>
        {isUserProjectOwner && (
          <Tooltip title="Remove project">
            <DeleteIcon
              color={'primary'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                onProjectRemove(row.uuid);
              }}
            />
          </Tooltip>
        )}
      </>
    );
  }),
];
