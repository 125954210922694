import { ApiListData } from '../../types/api';
import { createDefaultListApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import { getTasks, TasksAction } from '../actions/tasks';

type TasksState = ApiListData<Task>;
const initialState: TasksState = createDefaultListApiData();

export const tasks = (
  state: TasksState = initialState,
  action: TasksAction
): TasksState => {
  switch (action.type) {
    case getType(getTasks.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getTasks.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getType(getTasks.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
