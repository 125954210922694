import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SelectFieldProps, SelectOption } from '../../types/form';
import { SelectProps } from '@material-ui/core/Select/Select';

export const FormSelectField = <FormValues extends any>({
  register,
  errors,
  name,
  validationOptions,
  label,
  options,
  setValue,
  defaultValue,
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  watch,
  ...props
}: SelectProps & SelectFieldProps<FormValues>) => {
  const [currentValue, setCurrentValue] = useState<SelectOption['key'] | null>(
    ''
  );

  const changeValue = useCallback(
    (value: SelectOption['key'] | null) => {
      setCurrentValue(value);
      setValue(name, value);
    },
    [name, setValue]
  );

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    changeValue((defaultValue as string) || '');
  }, [defaultValue, changeValue, options]);

  return (
    <FormControl fullWidth style={style}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...props}
        name={name}
        value={currentValue}
        onChange={({ target: { value } }) => {
          changeValue(value as string);
        }}
        error={!!errors && !!errors[name]}
      >
        {options.map(({ key, label }, i) => (
          <MenuItem value={key} key={i}>
            {key === '' ? <em>{label}</em> : label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
