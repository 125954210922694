import React, { FC, ReactNode } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { ServerErrorMessage } from './ServerErrorMessage';
import { StyledAppBar } from './AppBar';
import { Footer } from './Footer';
import { useTitle } from '../../library/hooks/useTitle';

const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: `calc(100vh - 70px)`,
  padding: theme.spacing(3),
  paddingBottom: 0,
}));

interface Props {
  title?: string;
  controlPanelComponent?: ReactNode;
}

export const PrivatePageContainer: FC<Props> = ({
  children,
  title,
  controlPanelComponent,
}) => {
  useTitle(title ? title : '');

  return (
    <>
      <StyledAppBar />
      <PageWrapper>
        <Wrapper>
          <div>
            <Toolbar />

            <ServerErrorMessage />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              {title && (
                <>
                  <Typography variant="h6" component="h6">
                    {title}
                  </Typography>

                  {!!controlPanelComponent && controlPanelComponent}
                </>
              )}
            </div>

            {children}
          </div>
        </Wrapper>
        <Footer />
      </PageWrapper>
    </>
  );
};
