import { removeCategoryApiRequest } from '../../../store/actions/categoryRemove';
import { useState } from 'react';
import { useDialog } from '../../../hooks/useDialog';
import { useDispatch } from 'react-redux';

export const useRemoveCategory = (projectUuid: string) => {
  const [removeCategoryUuid, setRemoveCategoryUuid] = useState<string | null>(
    null
  );

  const { openDialog, closeDialog } = useDialog();

  const dispatch = useDispatch();

  const closeRemoveDialog = () => {
    closeDialog();
    setRemoveCategoryUuid(null);
  };

  const onCategoryRemove = (uuid: string) => {
    setRemoveCategoryUuid(uuid);
    openDialog('removeCategoryDialog');
  };

  const removeCategory = () => {
    dispatch(removeCategoryApiRequest(removeCategoryUuid!, projectUuid));
    closeRemoveDialog();
  };

  return { onCategoryRemove, removeCategory, closeRemoveDialog };
};
