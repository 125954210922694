import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Project } from '../../types/project';

export const getProjectApiRequest = (uuid: string) =>
  createApiAction(apiUrl(`projects/${uuid}`), '@project/GET_PROJECT', 'GET');

export const getProject = createAsyncAction(
  '@project/GET_PROJECT_REQUEST',
  '@project/GET_PROJECT_SUCCESS',
  '@project/GET_PROJECT_FAILURE'
)<undefined, ApiResponse<Project>, ApiError>();

export const clearProjectState = createAction('@project/CLEAR_PROJECT_STATE')();

export type ProjectAction = ActionType<
  typeof getProject | typeof clearProjectState
>;
