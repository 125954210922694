import { FormData, Task } from '../../types/task';

export const createFormData = (task?: Task): FormData => ({
  name: task?.name || '',
  categoryUuids: task ? task.categories.map((c) => c.uuid) : [],
  assignedUserUuid: task?.assignedUser?.uuid || '',
  description: task?.description || '',
  dependency: task?.dependency || '',
  status: task?.status || 'ready',
});

export const createApiPayload = (formData: FormData): FormData => ({
  ...formData,
  dependency: '',
  assignedUsers: (Array.isArray(formData.assignedUsers)
    ? formData.assignedUsers
    : [formData.assignedUsers]
  ).filter((user) => !!user),
});
