import { createStyles, styled, Typography } from '@material-ui/core';
import whiteLogo from '../../assets/images/logo512.png';
import React, { FC } from 'react';
import { CenteredText } from '../CenteredText';
import { Footer } from './Footer';
import { makeStyles } from '@material-ui/core/styles';
import { StyledAppBar } from './AppBar';

const Wrapper = styled('div')(() => ({
  width: '100%',
}));

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
}));

const Logo = styled('div')(({ theme }) => ({
  width: '50%',
  background: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 'calc(100vh - 70px)',
  justifyContent: 'center',
  flexGrow: 1,
}));

const RightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '50%',
  padding: `0 ${theme.spacing(3)}px`,
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export const PublicPageContainer: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Wrapper>
      <div className={classes.appBar}>
        <StyledAppBar />
      </div>
      <ContentWrapper>
        <Logo className={classes.logo}>
          <CenteredText>
            <Typography
              variant="h2"
              component="h2"
              style={{ color: '#fff', fontSize: '5.5rem' }}
            >
              Flowlify
            </Typography>
            <img src={whiteLogo} alt="" />
          </CenteredText>
        </Logo>
        <RightSide>
          <FormContainer>{children}</FormContainer>
          <Footer />
        </RightSide>
      </ContentWrapper>
    </Wrapper>
  );
};
