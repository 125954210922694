import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import {
  removeInvitation,
  InvitationRemoveAction,
} from '../actions/invitationRemove';

type InvitationRemoveState = ApiSingleData<undefined>;
const initialState: InvitationRemoveState = createDefaultSingleApiData();

export const invitationRemove = (
  state: InvitationRemoveState = initialState,
  action: InvitationRemoveAction
): InvitationRemoveState => {
  switch (action.type) {
    case getType(removeInvitation.request):
      return {
        ...state,
        loading: true,
      };

    case getType(removeInvitation.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(removeInvitation.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
