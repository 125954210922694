import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import {
  dismissInvitation,
  InvitationDismissAction,
} from '../actions/invitationDismiss';

type InvitationDismissState = ApiSingleData<undefined>;
const initialState: InvitationDismissState = createDefaultSingleApiData();

export const invitationDismiss = (
  state: InvitationDismissState = initialState,
  action: InvitationDismissAction
): InvitationDismissState => {
  switch (action.type) {
    case getType(dismissInvitation.request):
      return {
        ...state,
        loading: true,
      };

    case getType(dismissInvitation.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(dismissInvitation.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
