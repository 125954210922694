import React from 'react';
import { DataGridColumn, DataGridRowType } from '../../types/dataGrid';
import { StyledTableCell } from '../table/StyledTableCell';
import { StyledTableRow } from '../table/StyledTableRow';

const getCells = <RowType extends any>(
  index: number,
  columns: Array<DataGridColumn<RowType>>,
  row: RowType
): JSX.Element[] =>
  columns.map((column, i: number) => {
    let cellContent: string | number | JSX.Element = '';

    if (row[column.name] && !column.customTemplate) {
      cellContent = row[column.name];
    } else if (column.customTemplate) {
      cellContent = column.customTemplate(row, index);
    }

    return (
      <StyledTableCell
        scope="row"
        key={i}
        style={{ textAlign: i === columns.length - 1 ? 'right' : 'left' }}
      >
        {cellContent}
      </StyledTableCell>
    );
  });

interface DataGridRowProps<RowType> {
  index: number;
  columns: Array<DataGridColumn<RowType>>;
  row: DataGridRowType;
}

export const DataGridRow = ({ index, columns, row }: DataGridRowProps<any>) => (
  <StyledTableRow hover>{getCells(index, columns, row)}</StyledTableRow>
);
