import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import {
  createInvitation,
  InvitationCreateAction,
} from '../actions/invitationCreate';

type InvitationCreateState = ApiSingleData<undefined>;
const initialState: InvitationCreateState = createDefaultSingleApiData();

export const invitationCreate = (
  state: InvitationCreateState = initialState,
  action: InvitationCreateAction
): InvitationCreateState => {
  switch (action.type) {
    case getType(createInvitation.request):
      return {
        ...state,
        loading: true,
      };

    case getType(createInvitation.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(createInvitation.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
