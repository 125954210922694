import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoginPage } from '../pages/login/LoginPage';
import { PrivateRoute } from './PrivateRoute';
import { HomepagePage } from '../pages/homepage/HomepagePage';
import { routes } from '../routes/routes';
import { SignupPage } from '../pages/signup/SignupPage';
import { TasksPage } from '../pages/tasks/TasksPage';
import { CategoriesPage } from '../pages/categories/CategoriesPage';
import { UserProfilePage } from '../pages/userProfile/UserProfilePage';
import { PasswordChangePage } from '../pages/passwordChange/PasswordChangePage';
import { TaskDetailPage } from '../pages/taskDetail/TaskDetailPage';

export const Router = () => (
  <Switch>
    <Route path={routes.login}>
      <LoginPage />
    </Route>
    <Route path={routes.signup}>
      <SignupPage />
    </Route>
    <PrivateRoute exact path={routes.homepage}>
      <HomepagePage />
    </PrivateRoute>
    <PrivateRoute exact path={routes.tasks(':projectUuid')}>
      <TasksPage />
    </PrivateRoute>
    <PrivateRoute exact path={routes.taskDetail(':projectUuid', ':uuid')}>
      <TaskDetailPage />
    </PrivateRoute>
    <PrivateRoute exact path={routes.categories(':projectUuid')}>
      <CategoriesPage />
    </PrivateRoute>
    <PrivateRoute exact path={routes.userProfile}>
      <UserProfilePage />
    </PrivateRoute>
    <PrivateRoute exact path={routes.passwordChange}>
      <PasswordChangePage />
    </PrivateRoute>
  </Switch>
);
