import * as React from 'react';
import { ColoredBadge } from '../ColoredBadge';
import { nbsp } from '../../services/strings/strings';
import { Category } from '../../types/category';

interface Props {
  categories: Category[];
  displayStyle?: 'row' | 'column';
}

export const CategoryBadgeList = ({ categories, displayStyle }: Props) => (
  <>
    <div style={{ display: displayStyle === 'row' ? 'flex' : 'block' }}>
      {categories.map((category, i) => (
        <div
          key={category.uuid}
          style={{
            marginBottom: i < categories.length - 1 ? 8 : 0,
            marginRight: displayStyle === 'row' ? 8 : 0,
          }}
        >
          <ColoredBadge
            color={category.color}
            content={
              <b
                dangerouslySetInnerHTML={{
                  __html: nbsp(category.name),
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  </>
);
