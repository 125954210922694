import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import {
  changeUserPassword,
  clearUserPasswordChangeState,
  UserPasswordChangeAction,
} from '../actions/userPasswordChange';
import { User } from '../../types/user';

type UserPasswordChangeState = ApiSingleData<User>;
const initialState: UserPasswordChangeState = createDefaultSingleApiData();

export const userPasswordChange = (
  state: UserPasswordChangeState = initialState,
  action: UserPasswordChangeAction
): UserPasswordChangeState => {
  switch (action.type) {
    case getType(changeUserPassword.request):
      return {
        ...state,
        loading: true,
      };

    case getType(changeUserPassword.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(changeUserPassword.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getType(clearUserPasswordChangeState):
      return initialState;

    default:
      return state;
  }
};
