import { ApiError } from '../../types/api';

export const getFirstErrorMessage = (error: ApiError | null): string | null =>
  !!error?.messages && !!error.messages[0].messages
    ? error.messages[0].messages[0]
    : null;

export const isAuthError = (error: ApiError | null): boolean =>
  error?.status === 401;

export const isInvalidCredentialsError = (error: ApiError | null): boolean =>
  error?.status === 401 &&
  getFirstErrorMessage(error) === 'Invalid credentials.';

export const isExpiredSessionErrorMessage = (error: ApiError | null): boolean =>
  error?.status === 401 && getFirstErrorMessage(error) === 'Expired JWT Token';

export const isServerError = (error: ApiError | null): boolean =>
  error?.status === 500 && getFirstErrorMessage(error) === 'Server error';

export const isNetworkError = (error: ApiError | null): boolean =>
  getFirstErrorMessage(error) === 'Network error';
