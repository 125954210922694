import { Action } from 'redux';
import { Method } from 'axios';

export const API_REQUEST_TYPE = '@api/API_REQUEST';

export interface ApiResponse<T> {
  data: T;
}

export interface ApiErrorMessage {
  context: string;
  messages: string[];
}

export interface ApiError {
  status?: number;
  messages?: ApiErrorMessage[];
}

interface ApiData<T> {
  loading: boolean;
  data: T;
  error: ApiError | null;
}

export type ApiSingleData<T> = ApiData<T | null>;

export type ApiListData<T> = ApiData<T[] | null>;

export interface ApiPayload<D extends object> {
  url: string;
  actionPrefix: string;
  method?: Method;
  data?: object;
  headers?: object;
  onSuccess?: Array<(data: D) => Action>;
  applyAuthToken?: boolean;
}

export interface ApiAction<D extends object> {
  type: typeof API_REQUEST_TYPE;
  payload: ApiPayload<D>;
}
