import React, { FC } from 'react';
import { StyledAvatar } from './StyledAvatar';
import { AvatarProps } from '@material-ui/core';

const AVATAR_SIZE = 28;

const userInitials = (username: string) =>
  username
    .split(' ')
    .slice(0, 2)
    .map((name) => name[0].toUpperCase())
    .join('');

interface Props {
  username: string;
  color: string;
}

export const UserAvatar: FC<Props & AvatarProps> = ({
  username,
  color,
  ...props
}) => {
  const styledAvatar = (
    // @ts-ignore
    <StyledAvatar
      color={color}
      style={{
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        fontSize: 14,
        marginRight: 8,
      }}
      {...props}
    >
      {username === 'N/A' ? 'N/A' : userInitials(username)}
    </StyledAvatar>
  );

  return styledAvatar;
};
