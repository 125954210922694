import React from 'react';

import { Clear as ClearIcon } from '@material-ui/icons';
import { gridColumn } from '../../../services/dataGrid/dataGrid';
import { DataGridColumn } from '../../../types/dataGrid';
import { Invitation } from '../../../types/invitation';
import { UserNameAvatar } from '../../../components/UserNameAvatar';
import { ColoredBadge } from '../../../components/ColoredBadge';
import { nbsp } from '../../../services/strings/strings';
import { lightBlue, lightGreen, red } from '@material-ui/core/colors';
import { Tooltip } from '@material-ui/core';

const statusColor = (status: Invitation['status']): string => {
  const statusMap: Record<string, string> = {
    pending: lightBlue[500],
    accepted: lightGreen[500],
    dismissed: red[500],
  };

  return statusMap[status];
};

export const createInvitationsGrid = (
  onInvitationRemove: (uuid: string) => void
): DataGridColumn<Invitation>[] => [
  gridColumn('invitedUser', 'Invited user', ({ inviteeUser }) =>
    !!inviteeUser ? (
      <UserNameAvatar
        username={inviteeUser.name}
        color={inviteeUser.avatarColor}
      />
    ) : (
      <></>
    )
  ),
  gridColumn('email', 'E-mail'),
  gridColumn('status', 'Status', ({ status }) => (
    <ColoredBadge
      color={statusColor(status)}
      content={
        <b
          dangerouslySetInnerHTML={{
            __html: nbsp(status),
          }}
        />
      }
    />
  )),
  gridColumn('actions', 'Actions', (row) => (
    <>
      <Tooltip title="Remove invitation and user from project">
        <ClearIcon
          color="primary"
          style={{
            cursor: 'pointer',
            marginRight: 8,
          }}
          onClick={() => {
            onInvitationRemove(row.uuid);
          }}
        />
      </Tooltip>
    </>
  )),
];
