import { useDispatch } from 'react-redux';
import { useSelector } from './useSelector';
import { closeDialog, openDialog } from '../store/actions/dialog';

export const useDialog = () => {
  const dialog = useSelector((state) => state.dialog);

  const dispatch = useDispatch();

  const getDialog = (dialogTitle: string): string | undefined =>
    dialog.openedDialogs.find((title) => title === dialogTitle);

  const isDialogOpen = (name: string): boolean => getDialog(name) !== undefined;

  return {
    openDialog: (title: string) => {
      dispatch(openDialog({ title }));
    },
    closeDialog: () => {
      dispatch(closeDialog());
    },
    getDialog,
    isDialogOpen,
  };
};
