import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { User } from '../../types/user';
import { push } from 'connected-react-router';

export const loginUserApiRequest = (username: string, password: string) =>
  createApiAction(apiUrl('users/login'), '@user/LOGIN', 'POST', {
    data: { username, password },
    applyAuthToken: false,
    onSuccess: [() => push('/')],
  });

export const loginUser = createAsyncAction(
  '@user/LOGIN_REQUEST',
  '@user/LOGIN_SUCCESS',
  '@user/LOGIN_FAILURE'
)<undefined, ApiResponse<{ user: User; authToken: string }>, ApiError>();

export const signupUserApiRequest = (
  name: string,
  username: string,
  password: string
) =>
  createApiAction(apiUrl('users'), '@user/SIGNUP', 'POST', {
    data: { name, username, password },
    applyAuthToken: false,
  });

export const signupUser = createAsyncAction(
  '@user/SIGNUP_REQUEST',
  '@user/SIGNUP_SUCCESS',
  '@user/SIGNUP_FAILURE'
)<undefined, ApiResponse<User>, ApiError>();

export const getCurrentUserApiRequest = () =>
  createApiAction(apiUrl('users/me'), '@user/GET_CURRENT_USER', 'GET');

export const getCurrentUser = createAsyncAction(
  '@user/GET_CURRENT_USER_REQUEST',
  '@user/GET_CURRENT_USER_SUCCESS',
  '@user/GET_CURRENT_USER_FAILURE'
)<undefined, ApiResponse<{ user: User }>, ApiError>();

export const clearUserSignupState = createAction(
  '@user/CLEAR_USER_SIGNUP_STATE'
)();

export type UserAction = ActionType<
  | typeof loginUser
  | typeof signupUser
  | typeof getCurrentUser
  | typeof clearUserSignupState
>;
