import { SelectOption } from '../../types/form';

export const validationRequired = () => ({
  required: {
    value: true,
    message: 'This field is required',
  },
});

export const validationEmail = () => ({
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Invalid e-mail address',
  },
});

export const selectOption = (key: string, label: string): SelectOption => ({
  key,
  label,
});
