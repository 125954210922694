import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Task } from '../../types/task';
import { getTasksApiRequest } from './tasks';

export const createTaskApiRequest = (projectUuid: string, formData: FormData) =>
  createApiAction(apiUrl('tasks'), '@task/CREATE_TASK', 'POST', {
    data: { projectUuid, ...formData },
    onSuccess: [() => getTasksApiRequest(projectUuid)],
  });

export const createTask = createAsyncAction(
  '@task/CREATE_TASK_REQUEST',
  '@task/CREATE_TASK_SUCCESS',
  '@task/CREATE_TASK_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskCreateAction = ActionType<typeof createTask>;
