import React from 'react';
import { styled } from '@material-ui/core';
import { FC } from 'react';

const Badge = styled('div')(({ theme }) => ({
  borderRadius: 10,
  height: 20,
  padding: '0 6px',
  background: theme.palette.primary.main,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '0.75rem',
  lineHeight: 1,
  transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  minWidth: 20,
}));

interface Props {
  content: string | React.ReactNode;
  color: string;
}

export const ColoredBadge: FC<Props> = ({ content, color }) => (
  <Badge style={{ background: color }}>{content}</Badge>
);
