import React, { FC, useEffect } from 'react';
import { ActiveTaskTab, ProjectTabs } from '../project/ProjectTabs';
import { ErrorMessage } from '../ErrorMessage';
import { Link, useParams } from 'react-router-dom';
import { PrivatePageContainer } from './PrivatePageContainer';
import { useSelector } from '../../hooks/useSelector';
import { getProjectApiRequest } from '../../store/actions/project';
import { useDispatch } from 'react-redux';
import { ProjectControlPanelComponent } from './ProjectControlPanelComponent';

interface Props {
  activeTab: ActiveTaskTab;
}

export const ProjectPageContainer: FC<Props> = ({ activeTab, children }) => {
  const { projectUuid } = useParams<{ projectUuid: string }>();

  const { data: project, error: projectError } = useSelector(
    (state) => state.project
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectApiRequest(projectUuid));
  }, [dispatch, projectUuid]);

  return (
    <PrivatePageContainer
      title={project?.name}
      controlPanelComponent={
        <ProjectControlPanelComponent projectUuid={project?.uuid} />
      }
    >
      {projectError?.status === 404 ? (
        <ErrorMessage>
          Project not found. <Link to="/">Get back to dashboard</Link>.
        </ErrorMessage>
      ) : (
        <>
          <ProjectTabs projectUuid={projectUuid} activeTab={activeTab} />

          {children}
        </>
      )}
    </PrivatePageContainer>
  );
};
