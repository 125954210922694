import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Project } from '../../types/project';
import { getProjectsApiRequest } from './projects';

export const removeProjectApiRequest = (uuid: string) =>
  createApiAction(
    apiUrl(`projects/${uuid}`),
    '@project/REMOVE_PROJECT',
    'DELETE',
    {
      onSuccess: [() => getProjectsApiRequest()],
    }
  );

export const removeProject = createAsyncAction(
  '@project/REMOVE_PROJECT_REQUEST',
  '@project/REMOVE_PROJECT_SUCCESS',
  '@project/REMOVE_PROJECT_FAILURE'
)<undefined, ApiResponse<Project>, ApiError>();

export type ProjectRemoveAction = ActionType<typeof removeProject>;
