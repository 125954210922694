import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { getActiveIndex } from '../../services/tabs/tabs';
import Paper from '@material-ui/core/Paper';
import { Tab, Tabs } from '@material-ui/core';
import { push } from 'connected-react-router';
import { UiTab } from '../../types/tabs';

interface Props {
  tabs: UiTab[];
  activeTab: string;
}

export const TabsMenu: FC<Props> = ({ tabs, activeTab }) => {
  const dispatch = useDispatch();

  const active = getActiveIndex(tabs, activeTab);

  return (
    <Paper square style={{ marginBottom: 32 }}>
      <Tabs
        value={active}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, value) => {
          dispatch(push(tabs[value].link));
        }}
      >
        {tabs.map((tab, i) => (
          <Tab label={tab.label} key={i} />
        ))}
      </Tabs>
    </Paper>
  );
};
