import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import { createTask, TaskCreateAction } from '../actions/taskCreate';

type TaskCreateState = ApiSingleData<Task>;
const initialState: TaskCreateState = createDefaultSingleApiData();

export const taskCreate = (
  state: TaskCreateState = initialState,
  action: TaskCreateAction
): TaskCreateState => {
  switch (action.type) {
    case getType(createTask.request):
      return {
        ...state,
        loading: true,
      };

    case getType(createTask.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(createTask.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
