import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Project } from '../../types/project';
import { updateProject, ProjectUpdateAction } from '../actions/projectUpdate';

type ProjectUpdateState = ApiSingleData<Project>;
const initialState: ProjectUpdateState = createDefaultSingleApiData();

export const projectUpdate = (
  state: ProjectUpdateState = initialState,
  action: ProjectUpdateAction
): ProjectUpdateState => {
  switch (action.type) {
    case getType(updateProject.request):
      return {
        ...state,
        loading: true,
      };

    case getType(updateProject.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(updateProject.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
