import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { User } from '../../types/user';
import { getCurrentUser, loginUser, UserAction } from '../actions/user';

type UserState = ApiSingleData<User>;
const initialState: UserState = createDefaultSingleApiData();

export const user = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case getType(getCurrentUser.request):
    case getType(loginUser.request):
      return {
        ...state,
        loading: true,
      };

    case getType(getCurrentUser.success):
    case getType(loginUser.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data.user,
        error: null,
      };

    case getType(getCurrentUser.failure):
    case getType(loginUser.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
