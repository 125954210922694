import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Project } from '../../types/project';

export const getProjectsApiRequest = () =>
  createApiAction(apiUrl('projects'), '@projects/GET_PROJECTS', 'GET');

export const getProjects = createAsyncAction(
  '@projects/GET_PROJECTS_REQUEST',
  '@projects/GET_PROJECTS_SUCCESS',
  '@projects/GET_PROJECTS_FAILURE'
)<undefined, ApiResponse<Project[]>, ApiError>();

export type ProjectsAction = ActionType<typeof getProjects>;
