import React, { FC } from 'react';
import { routes } from '../../routes/routes';
import { UiTab } from '../../types/tabs';
import { createTab } from '../../services/tabs/tabs';
import { TabsMenu } from '../layout/TabsMenu';

const createTabs = (): UiTab[] => [
  createTab('profile', 'Profile', routes.userProfile),
  createTab('passwordChange', 'Password change', routes.passwordChange),
];

export type ActiveUserProfileTab = 'profile' | 'passwordChange';

interface Props {
  activeTab: ActiveUserProfileTab;
}

export const UserProfileTabs: FC<Props> = ({ activeTab }) => (
  <TabsMenu tabs={createTabs()} activeTab={activeTab} />
);
