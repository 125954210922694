import React from 'react';
import { CircularProgress, styled } from '@material-ui/core';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 'calc(100vh - 64px)',
}));

export const LoadingScreen = () => (
  <Wrapper>
    <CircularProgress size={60} />
  </Wrapper>
);
