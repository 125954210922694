import { Action, combineReducers } from 'redux';
import { StateType, getType } from 'typesafe-actions';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';

import { tasks } from './tasks';
import { users } from './users';
import { resetStore } from '../actions/reset';
import { user } from './user';
import { userSignup } from './userSignup';
import { projects } from './projects';
import { dialog } from './dialog';
import { projectCreate } from './projectCreate';
import { project } from './project';
import { projectUpdate } from './projectUpdate';
import { categories } from './categories';
import { taskCreate } from './taskCreate';
import { categoryCreate } from './categoryCreate';
import { categoryUpdate } from './categoryUpdate';
import { category } from './category';
import { task } from './task';
import { taskUpdate } from './taskUpdate';
import { authError } from './authError';
import { taskToggleHighPriority } from './taskToggleHighPriority';
import { taskComplete } from './taskComplete';
import { categoryRemove } from './categoryRemove';
import { taskRemove } from './taskRemove';
import { projectRemove } from './projectRemove';
import { invitationCreate } from './invitationCreate';
import { invitations } from './invitations';
import { invitationAccept } from './invitationAccept';
import { invitationDismiss } from './invitationDismiss';
import { invitationRemove } from './invitationRemove';
import { taskDependencyUnlock } from './taskDependencyUnlock';
import { userPasswordChange } from './userPasswordChange';

const reducers = {
  authError,
  category,
  categoryCreate,
  categoryUpdate,
  categoryRemove,
  categories,
  dialog,
  invitations,
  invitationCreate,
  invitationRemove,
  invitationAccept,
  invitationDismiss,
  project,
  projectCreate,
  projectUpdate,
  projectRemove,
  projects,
  task,
  taskCreate,
  taskUpdate,
  taskComplete,
  taskRemove,
  taskDependencyUnlock,
  taskToggleHighPriority,
  tasks,
  user,
  userSignup,
  userPasswordChange,
  users,
};

export const createAppReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

export const createRootReducer = (history: History) => (
  state: AppState | undefined,
  action: Action
) => {
  if (action.type === getType(resetStore)) {
    state = undefined;
  }

  return createAppReducer(history)(state, action);
};

export type AppState = StateType<typeof reducers> & { router: RouterState };
