import React, { useCallback, useEffect, useState } from 'react';
import { GroupAvatarFieldProps, SelectOption } from '../../types/form';
import { AvatarGroup } from '@material-ui/lab';
import { UserAvatar } from '../UserAvatar';

export const FormGroupAvatarField = <FormValues extends any>({
  register,
  name,
  validationOptions,
  options,
  setValue,
  initialValue,
}: GroupAvatarFieldProps<FormValues>) => {
  const [currentValue, setCurrentValue] = useState<SelectOption['key'][]>([]);

  const changeValue = useCallback(
    (value: SelectOption['key'][]) => {
      setCurrentValue(value);
      setValue(name, value);
    },
    [name, setValue]
  );

  const onValueChange = (value: SelectOption['key']) => {
    const newValue = currentValue.includes(value)
      ? currentValue.filter((v) => v !== value)
      : currentValue.concat(value);

    changeValue(newValue);
  };

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    changeValue(initialValue || []);
  }, [initialValue, changeValue]);

  return (
    <AvatarGroup>
      {options.map(({ key, label, color }) => (
        <UserAvatar
          username={label}
          color={color}
          key={key}
          onClick={() => {
            onValueChange(key);
          }}
          style={{
            border: '2px solid #fff',
            boxShadow: `0 0 ${currentValue.includes(key) ? '2px' : 0} #000`,
            cursor: 'pointer',
          }}
        />
      ))}
    </AvatarGroup>
  );
};
