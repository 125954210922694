import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { User } from '../../types/user';

export const getCompanyUsersApiRequest = (companyUuid: string) =>
  createApiAction(
    apiUrl(`companies/${companyUuid}/users`),
    '@users/GET_USERS',
    'GET'
  );

export const getProjectUsersApiRequest = (projectUuid: string) =>
  createApiAction(
    apiUrl(`projects/${projectUuid}/users`),
    '@users/GET_USERS',
    'GET'
  );

export const getUsers = createAsyncAction(
  '@users/GET_USERS_REQUEST',
  '@users/GET_USERS_SUCCESS',
  '@users/GET_USERS_FAILURE'
)<undefined, ApiResponse<User[]>, ApiError>();

export type UsersAction = ActionType<typeof getUsers>;
