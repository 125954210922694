import React from 'react';

import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import { gridColumn } from '../../../services/dataGrid/dataGrid';
import { DataGridColumn } from '../../../types/dataGrid';
import { Invitation } from '../../../types/invitation';
import { UserNameAvatar } from '../../../components/UserNameAvatar';
import { Tooltip } from '@material-ui/core';

export const createPendingInvitationsGrid = (
  onInvitationAccept: (uuid: string) => void,
  onInvitationDismiss: (uuid: string) => void
): DataGridColumn<Invitation>[] => [
  gridColumn('projectName', 'Invitation', (row) => <>{row.project.name}</>),
  gridColumn('userName', 'Inviter', ({ user }) => (
    <UserNameAvatar username={user.name} color={user.avatarColor} />
  )),
  gridColumn('actions', 'Actions', (row) => (
    <>
      <Tooltip title="Accept invitation">
        <CheckIcon
          color="primary"
          style={{
            cursor: 'pointer',
            marginRight: 8,
          }}
          onClick={() => {
            onInvitationAccept(row.uuid);
          }}
        />
      </Tooltip>
      <Tooltip title="Dismiss invitation">
        <ClearIcon
          color="primary"
          style={{
            cursor: 'pointer',
            marginRight: 8,
          }}
          onClick={() => {
            onInvitationDismiss(row.uuid);
          }}
        />
      </Tooltip>
    </>
  )),
];
