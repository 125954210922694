import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import { getProjectsApiRequest } from '../../store/actions/projects';
import { DataGrid } from '../../components/dataGrid/DataGrid';
import { FloatingAddIcon } from '../../components/FloatingAddIcon';
import { useDialog } from '../../hooks/useDialog';
import { ProjectFormDialog } from './ProjectFormDialog';
import { getProjectApiRequest } from '../../store/actions/project';
import { PrivatePageContainer } from '../../components/layout/PrivatePageContainer';
import { AlertDialog } from '../../AlertDialog';
import { InvitationFormDialog } from './InvitationFormDialog';
import { createProjectsGrid } from './grid/projectsGrid';
import { useRemoveProject } from './hooks/useRemoveProject';
import Typography from '@material-ui/core/Typography';
import { acceptInvitationApiRequest } from '../../store/actions/invitationAccept';
import { dismissInvitationApiRequest } from '../../store/actions/invitationDismiss';
import { getInvitationsApiRequest } from '../../store/actions/invitations';
import { useUser } from '../../hooks/useUser';
import { InvitationListDialog } from './InvitationListDialog';
import { createPendingInvitationsGrid } from './grid/pendingInvitationsGrid';

export const HomepagePage = () => {
  const [invitationProjectUuid, setInvitationProjectUuid] = useState<
    string | null
  >(null);
  const [invitationListProjectUuid, setInvitationListProjectUuid] = useState<
    string | null
  >(null);

  const dispatch = useDispatch();
  const { data: projects, loading: projectsLoading } = useSelector(
    (state) => state.projects
  );
  const { data: project, loading: projectLoading } = useSelector(
    (state) => state.project
  );
  const { data: invitations, loading: invitationsLoading } = useSelector(
    (state) => state.invitations
  );
  const { user } = useUser();

  const { isDialogOpen, openDialog } = useDialog();

  const {
    closeRemoveDialog,
    onProjectRemove,
    removeProject,
  } = useRemoveProject();

  const onProjectEdit = (uuid: string) => {
    dispatch(getProjectApiRequest(uuid));
    openDialog('projectDialog');
  };

  const onInvitationSend = (uuid: string) => {
    setInvitationProjectUuid(uuid);
    openDialog('invitationDialog');
  };

  const onInvitationListOpen = (uuid: string) => {
    dispatch(getProjectApiRequest(uuid));
    setInvitationListProjectUuid(uuid);
    openDialog('invitationListDialog');
  };

  const projectColumns = createProjectsGrid(
    user,
    onProjectEdit,
    onProjectRemove,
    onInvitationSend,
    onInvitationListOpen
  );

  const invitationColumns = createPendingInvitationsGrid(
    (uuid: string) => {
      dispatch(acceptInvitationApiRequest(uuid));
    },
    (uuid: string) => {
      dispatch(dismissInvitationApiRequest(uuid));
    }
  );

  const isInvitationDialogOpen = isDialogOpen('invitationDialog');

  useEffect(() => {
    dispatch(getProjectsApiRequest());
    dispatch(getInvitationsApiRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!isInvitationDialogOpen) {
      setInvitationProjectUuid(null);
    }
  }, [isInvitationDialogOpen]);

  return (
    <PrivatePageContainer>
      {invitations !== null && invitations.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <Typography variant="h6" component="h6" style={{ marginBottom: 16 }}>
            Invitations
          </Typography>

          <DataGrid
            data={invitations || []}
            isLoading={invitationsLoading && invitations === null}
            columns={invitationColumns}
          />
        </div>
      )}

      <Typography variant="h6" component="h6" style={{ marginBottom: 16 }}>
        Projects
      </Typography>

      <DataGrid
        data={projects || []}
        isLoading={projectsLoading && projects === null}
        columns={projectColumns}
      />

      <ProjectFormDialog
        isOpen={isDialogOpen('projectDialog')}
        project={project || undefined}
        projectLoading={projectLoading}
      />

      {!!invitationProjectUuid && (
        <InvitationFormDialog
          isOpen={isDialogOpen('invitationDialog')}
          projectUuid={invitationProjectUuid}
        />
      )}

      {!!invitationListProjectUuid && (
        <InvitationListDialog
          isOpen={isDialogOpen('invitationListDialog')}
          project={project}
          projectLoading={projectLoading}
        />
      )}

      <AlertDialog
        title="Remove project"
        text="Do you really want to remove selected project with it's tasks and categories?"
        onDialogClose={closeRemoveDialog}
        isOpen={isDialogOpen('removeProjectDialog')}
        onProceed={removeProject}
      />

      <FloatingAddIcon
        onClick={() => {
          openDialog('projectDialog');
        }}
      />
    </PrivatePageContainer>
  );
};
