import { ApiListData, ApiSingleData } from '../../types/api';

export const createDefaultSingleApiData = <T>(): ApiSingleData<T> => ({
  loading: false,
  data: null,
  error: null,
});

export const createDefaultListApiData = <T>(): ApiListData<T> => ({
  loading: false,
  data: null,
  error: null,
});
