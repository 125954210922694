import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import { TaskCompleteAction, completeTask } from '../actions/taskComplete';

type TaskCompleteState = ApiSingleData<Task>;
const initialState: TaskCompleteState = createDefaultSingleApiData();

export const taskComplete = (
  state: TaskCompleteState = initialState,
  action: TaskCompleteAction
): TaskCompleteState => {
  switch (action.type) {
    case getType(completeTask.request):
      return {
        ...state,
        loading: true,
      };

    case getType(completeTask.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(completeTask.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
