import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import { TaskFormDialog } from '../task/TaskForm';
import { useDialog } from '../../hooks/useDialog';

interface Props {
  projectUuid?: string;
}

export const ProjectControlPanelComponent = ({ projectUuid }: Props) => {
  const { openDialog, isDialogOpen } = useDialog();

  const isOpen = isDialogOpen('taskForm');

  return (
    <>
      <Fab color="secondary" size="small" style={{ color: '#fff' }}>
        <AddIcon
          onClick={() => {
            openDialog('taskForm');
          }}
        />
      </Fab>

      {!!projectUuid && (
        <TaskFormDialog projectUuid={projectUuid} isOpen={isOpen} />
      )}
    </>
  );
};
