import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routes } from '../routes/routes';
import { useUser } from '../hooks/useUser';
import { LoadingScreen } from '../components/LoadingScreen';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { isAuthenticated, isUserLoading } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isUserLoading ? (
          <LoadingScreen />
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
