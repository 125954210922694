import * as React from 'react';
import { ScrollableBox } from './ScrollableBox';
import { Table } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { FC } from 'react';

export const ScrollableTable: FC = ({ children }) => (
  <ScrollableBox scrollableStyle="x">
    <TableContainer component={Paper}>
      <Table>{children}</Table>
    </TableContainer>
  </ScrollableBox>
);
