import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Project } from '../../types/project';
import {
  clearProjectState,
  getProject,
  ProjectAction,
} from '../actions/project';

type ProjectState = ApiSingleData<Project>;
const initialState: ProjectState = createDefaultSingleApiData();

export const project = (
  state: ProjectState = initialState,
  action: ProjectAction
): ProjectState => {
  switch (action.type) {
    case getType(getProject.request):
      return {
        ...state,
        loading: true,
      };

    case getType(getProject.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(getProject.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getType(clearProjectState):
      return initialState;

    default:
      return state;
  }
};
