import React, { FC } from 'react';
import { routes } from '../../routes/routes';
import { UiTab } from '../../types/tabs';
import { createTab } from '../../services/tabs/tabs';
import { TabsMenu } from '../layout/TabsMenu';

const createTabs = (projectUuid: string): UiTab[] => [
  createTab('tasks', 'Tasks', routes.tasks(projectUuid)),
  createTab('categories', 'Categories', routes.categories(projectUuid)),
];

export type ActiveTaskTab = 'tasks' | 'categories';

interface Props {
  activeTab: ActiveTaskTab;
  projectUuid: string;
}

export const ProjectTabs: FC<Props> = ({ activeTab, projectUuid }) => (
  <TabsMenu tabs={createTabs(projectUuid)} activeTab={activeTab} />
);
