import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Invitation } from '../../types/invitation';
import { getProjectApiRequest } from './project';

export const removeInvitationApiRequest = (uuid: string, projectUuid: string) =>
  createApiAction(
    apiUrl(`invitations/${uuid}`),
    '@invitation/REMOVE_INVITATION',
    'DELETE',
    {
      onSuccess: [() => getProjectApiRequest(projectUuid)],
    }
  );

export const removeInvitation = createAsyncAction(
  '@invitation/REMOVE_INVITATION_REQUEST',
  '@invitation/REMOVE_INVITATION_SUCCESS',
  '@invitation/REMOVE_INVITATION_FAILURE'
)<undefined, ApiResponse<Invitation>, ApiError>();

export type InvitationRemoveAction = ActionType<typeof removeInvitation>;
