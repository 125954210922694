import { removeProjectApiRequest } from '../../../store/actions/projectRemove';
import { useState } from 'react';
import { useDialog } from '../../../hooks/useDialog';
import { useDispatch } from 'react-redux';

export const useRemoveProject = () => {
  const [removeProjectUuid, setRemoveProjectUuid] = useState<string | null>(
    null
  );

  const { openDialog, closeDialog } = useDialog();

  const dispatch = useDispatch();

  const closeRemoveDialog = () => {
    closeDialog();
    setRemoveProjectUuid(null);
  };

  const onProjectRemove = (uuid: string) => {
    setRemoveProjectUuid(uuid);
    openDialog('removeProjectDialog');
  };

  const removeProject = () => {
    dispatch(removeProjectApiRequest(removeProjectUuid!));
    closeRemoveDialog();
  };

  return {
    onProjectRemove,
    removeProject,
    closeRemoveDialog,
  };
};
