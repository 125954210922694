import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Check as CheckIcon, Star as StarIcon } from '@material-ui/icons';
import { theme } from '../../styles/theme';
import { Task } from '../../types/task';
import { isTaskDone, isTaskHighPriority } from '../../utils/task/taskFilter';

interface Props {
  tasks: Task[];
}

export const TaskCounts: FC<Props> = ({ tasks }) => (
  <Grid container alignItems="center">
    {tasks.filter(isTaskDone).length}
    <CheckIcon color="primary" style={{ marginRight: 8 }} />{' '}
    {
      tasks.filter((task) => isTaskHighPriority(task) && !isTaskDone(task))
        .length
    }{' '}
    <StarIcon color="primary" style={{ marginRight: 8 }} />{' '}
    {tasks.filter((task) => !isTaskDone(task)).length}&nbsp;
    <span style={{ fontSize: 22, color: theme.palette.primary.main }}>
      &Sigma;
    </span>
  </Grid>
);
