import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { PasswordChangeFormData, User } from '../../types/user';

export const changeUserPasswordApiRequest = (
  formData: PasswordChangeFormData
) =>
  createApiAction(
    apiUrl(`users/password-change`),
    '@userPasswordChange/CHANGE_PASSWORD',
    'PUT',
    {
      data: formData,
    }
  );

export const changeUserPassword = createAsyncAction(
  '@userPasswordChange/CHANGE_PASSWORD_REQUEST',
  '@userPasswordChange/CHANGE_PASSWORD_SUCCESS',
  '@userPasswordChange/CHANGE_PASSWORD_FAILURE'
)<undefined, ApiResponse<User>, ApiError>();

export const clearUserPasswordChangeState = createAction(
  '@userPasswordChange/CLEAR_STATE'
)();

export type UserPasswordChangeAction = ActionType<
  typeof changeUserPassword | typeof clearUserPasswordChangeState
>;
