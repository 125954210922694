import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Project } from '../../types/project';
import { getProjectsApiRequest } from './projects';
import { closeDialog } from './dialog';

export const createProjectApiRequest = (formData: FormData) =>
  createApiAction(apiUrl('projects'), '@project/CREATE_PROJECT', 'POST', {
    data: formData,
    onSuccess: [() => closeDialog(), () => getProjectsApiRequest()],
  });

export const createProject = createAsyncAction(
  '@project/CREATE_PROJECT_REQUEST',
  '@project/CREATE_PROJECT_SUCCESS',
  '@project/CREATE_PROJECT_FAILURE'
)<undefined, ApiResponse<Project>, ApiError>();

export type ProjectCreateAction = ActionType<typeof createProject>;
