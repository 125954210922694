import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Task } from '../../types/task';

export const getTasksApiRequest = (projectUuid: string) =>
  createApiAction(
    apiUrl(`projects/${projectUuid}/tasks`),
    '@tasks/GET_TASKS',
    'GET'
  );

export const getTasks = createAsyncAction(
  '@tasks/GET_TASKS_REQUEST',
  '@tasks/GET_TASKS_SUCCESS',
  '@tasks/GET_TASKS_FAILURE'
)<undefined, ApiResponse<Task[]>, ApiError>();

export type TasksAction = ActionType<typeof getTasks>;
