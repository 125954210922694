import { UiTab } from '../../types/tabs';

export const createTab = (key: string, label: string, link: string): UiTab => ({
  key,
  label,
  link,
});

export const getActiveIndex = (tabs: UiTab[], activeTab: string): number =>
  tabs.findIndex((tab) => tab.key === activeTab);
