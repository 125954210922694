import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Category } from '../../types/category';
import { getCategoriesApiRequest } from './categories';
import { closeDialog } from './dialog';

export const updateCategoryApiRequest = (
  uuid: string,
  projectUuid: string,
  formData: FormData
) =>
  createApiAction(
    apiUrl(`categories/${uuid}`),
    '@category/UPDATE_CATEGORY',
    'PUT',
    {
      data: formData,
      onSuccess: [
        () => closeDialog(),
        () => getCategoriesApiRequest(projectUuid),
      ],
    }
  );

export const updateCategory = createAsyncAction(
  '@category/UPDATE_CATEGORY_REQUEST',
  '@category/UPDATE_CATEGORY_SUCCESS',
  '@category/UPDATE_CATEGORY_FAILURE'
)<undefined, ApiResponse<Category>, ApiError>();

export type CategoryUpdateAction = ActionType<typeof updateCategory>;
