import React, { useEffect } from 'react';
import { Paper, styled } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { loginUserApiRequest } from '../../store/actions/user';
import { LoginForm } from './LoginForm';
import { useSelector } from '../../hooks/useSelector';
import { clearAuthError } from '../../store/actions/authError';
import {
  isExpiredSessionErrorMessage,
  isInvalidCredentialsError,
} from '../../services/api/apiError';
import { PublicPageContainer } from '../../components/layout/PublicPageContainer';
import { useTitle } from '../../library/hooks/useTitle';

const FormWrapper = styled(Paper)(({ theme }) => ({
  width: 360,
  padding: theme.spacing(3),
}));

interface FormData {
  username: string;
  password: string;
}

export const LoginPage = () => {
  useTitle('Login');

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.user);
  const authError = useSelector((state) => state.authError);

  const invalidCredentialsError = isInvalidCredentialsError(error);
  const expiredSessionErrorMessage = isExpiredSessionErrorMessage(authError);

  const onSubmit = ({ username, password }: FormData) => {
    dispatch(loginUserApiRequest(username, password));
  };

  useEffect(() => {
    return () => {
      dispatch(clearAuthError());
    };
  }, [dispatch]);

  return (
    <PublicPageContainer>
      <FormWrapper>
        <LoginForm
          loading={loading}
          invalidCredentialsError={invalidCredentialsError}
          expiredSessionError={expiredSessionErrorMessage}
          onSubmit={onSubmit}
        />
      </FormWrapper>
    </PublicPageContainer>
  );
};
