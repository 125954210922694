import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from 'redux';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { createRootReducer } from './reducers/reducers';
import { apiMiddleware } from './middleware/apiMiddleware';
import { authMiddleware } from './middleware/authMiddleware';

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const createStore = (history: History) =>
  createReduxStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(apiMiddleware, authMiddleware, routerMiddleware(history))
    )
  );
