import React, { FC } from 'react';
import { Check as CheckIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Task } from '../../../types/task';

interface Props {
  task: Task;
  onTaskComplete: (taskUuid: string) => void;
}

export const CompleteTaskIcon: FC<Props> = ({ task, onTaskComplete }) => {
  if (task.status === 'done') {
    return null;
  }

  return (
    <Tooltip title="Complete task">
      <CheckIcon
        color={'primary'}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          onTaskComplete(task.uuid);
        }}
      />
    </Tooltip>
  );
};
