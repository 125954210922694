import React from 'react';
import { CenteredText } from '../../components/CenteredText';
import { Typography } from '@material-ui/core';
import { FormTextField } from '../../components/form/FormTextField';
import { StyledButton } from '../../components/StyledButton';
import { StyledLink } from '../../components/StyledLink';
import { routes } from '../../routes/routes';
import { useForm } from 'react-hook-form';
import { validationRequired } from '../../services/form/form';
import { ServerErrorMessage } from '../../components/layout/ServerErrorMessage';

const userNameError = (message: string) => ({
  username: {
    type: 'required',
    message,
  },
});

interface FormData {
  username: string;
  password: string;
}

interface Props {
  loading: boolean;
  invalidCredentialsError?: boolean;
  expiredSessionError?: boolean;
  onSubmit: (formData: FormData) => void;
}

export const LoginForm = ({
  loading,
  invalidCredentialsError,
  expiredSessionError,
  onSubmit,
}: Props) => {
  const { register, errors, handleSubmit } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CenteredText>
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
      </CenteredText>

      <ServerErrorMessage />

      <FormTextField
        label="Username"
        name="username"
        style={{ marginBottom: 16 }}
        fullWidth
        register={register}
        errors={{
          ...errors,
          ...(invalidCredentialsError
            ? userNameError('Invalid credentials, please try again.')
            : expiredSessionError
            ? userNameError('Your session has expired, please login again.')
            : {}),
        }}
        validationOptions={{
          ...validationRequired(),
        }}
      />
      <FormTextField
        label="Password"
        type="password"
        name="password"
        style={{ marginBottom: 32 }}
        fullWidth
        register={register}
        errors={errors}
        validationOptions={{
          ...validationRequired(),
        }}
      />
      <StyledButton
        type="submit"
        style={{ marginBottom: 32 }}
        fullWidth
        isSubmitting={loading}
      >
        Login
      </StyledButton>

      <CenteredText>
        <StyledLink to={routes.signup}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          No account yet? Let's sign up
        </StyledLink>
      </CenteredText>
    </form>
  );
};
