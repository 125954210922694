import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getTasksApiRequest } from './tasks';
import { Task } from '../../types/task';
import { getTaskApiRequest } from './task';

export const completeTaskApiRequest = (uuid: string, projectUuid: string) =>
  createApiAction(apiUrl(`tasks/${uuid}/done`), '@task/COMPLETE_TASK', 'PUT', {
    onSuccess: [
      () => getTasksApiRequest(projectUuid),
      () => getTaskApiRequest(uuid),
    ],
  });

export const completeTask = createAsyncAction(
  '@task/COMPLETE_TASK_REQUEST',
  '@task/COMPLETE_TASK_SUCCESS',
  '@task/COMPLETE_TASK_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskCompleteAction = ActionType<typeof completeTask>;
