import React, { FC } from 'react';
import {
  ActiveUserProfileTab,
  UserProfileTabs,
} from '../userProfile/UserProfileTabs';
import { PrivatePageContainer } from './PrivatePageContainer';

interface Props {
  activeTab: ActiveUserProfileTab;
}

export const UserProfilePageContainer: FC<Props> = ({
  activeTab,
  children,
}) => (
  <PrivatePageContainer title="User profile">
    <UserProfileTabs activeTab={activeTab} />

    {children}
  </PrivatePageContainer>
);
