import React from 'react';
import { Grid } from '@material-ui/core';
import { FormCheckboxField } from '../../components/form/FormCheckboxField';
import { FormTextField } from '../../components/form/FormTextField';
import { FormContextValues } from 'react-hook-form';
import { FormGroupAvatarField } from '../../components/form/FormGroupAvatarField';
import { User } from '../../types/user';
import { grey } from '@material-ui/core/colors';

interface Props<FormValues extends any> {
  register: FormContextValues<FormValues>['register'];
  setValue: FormContextValues['setValue'];
  projectUsers: User[];
}

export const TaskFilter = <FormValues extends any>({
  register,
  setValue,
  projectUsers,
}: Props<FormValues>) => {
  const projectUsersOptions = projectUsers
    .map(({ name, uuid, avatarColor }) => ({
      key: uuid,
      label: name,
      color: avatarColor,
    }))
    .concat([
      {
        key: '',
        label: 'N/A',
        color: grey[500],
      },
    ]);

  return (
    <Grid container alignItems="center">
      <Grid item>
        <FormCheckboxField
          name="hideCompletedTasks"
          label="Hide completed tasks"
          defaultChecked={true}
          setValue={setValue}
          register={register}
        />
      </Grid>
      <Grid item>
        <FormCheckboxField
          name="noDependencyTasks"
          label="Show only no-dependency tasks"
          defaultChecked={false}
          setValue={setValue}
          register={register}
        />
      </Grid>
      <Grid item>
        <FormTextField
          name="fulltextQuery"
          label="Search"
          register={register}
          style={{ marginRight: 16, width: 300 }}
        />
      </Grid>
      <Grid item>
        <FormGroupAvatarField
          name="assignedUsers"
          register={register}
          setValue={setValue}
          options={projectUsersOptions}
        />
      </Grid>
    </Grid>
  );
};
