import * as React from 'react';
import { StarTaskIcon } from '../../pages/tasks/actionIcons/StarTaskIcon';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { BorderColor as InlineEditIcon } from '@material-ui/icons';
import { DeleteTaskIcon } from '../../pages/tasks/actionIcons/DeleteTaskIcon';
import { CompleteTaskIcon } from '../../pages/tasks/actionIcons/CompleteTaskIcon';
import { Task } from '../../types/task';

interface Props {
  task: Task;
  selectedTaskUuid: string | null;
  selectedTaskLoading: boolean;
  editTask: (taskUuid: string) => void;
  toggleTaskHighPriority: (taskUuid: string) => void;
  onTaskRemove: (taskUuid: string) => void;
  completeTask: (taskUuid: string) => void;
  // openEditTaskForm: (taskUuid: string) => void;
}

export const TaskActions = ({
  task,
  selectedTaskUuid,
  selectedTaskLoading,
  editTask,
  toggleTaskHighPriority,
  onTaskRemove,
  completeTask,
}: // openEditTaskForm,
Props) => (
  <>
    <span style={{ marginRight: 8 }}>
      <StarTaskIcon
        task={task}
        toggleTaskHighPriority={toggleTaskHighPriority}
      />
    </span>

    {/*<Tooltip title="Edit task">*/}
    {/*  <EditIcon*/}
    {/*    color={'primary'}*/}
    {/*    style={{*/}
    {/*      cursor: 'pointer',*/}
    {/*      marginRight: 8,*/}
    {/*    }}*/}
    {/*    onClick={() => {*/}
    {/*      openEditTaskForm(task.uuid);*/}
    {/*    }}*/}
    {/*  />*/}
    {/*</Tooltip>*/}

    {selectedTaskUuid !== task.uuid || !selectedTaskLoading ? (
      <Tooltip title="Inline edit task">
        <InlineEditIcon
          color={'primary'}
          style={{
            cursor: 'pointer',
            marginRight: 8,
          }}
          onClick={() => {
            editTask(task.uuid);
          }}
        />
      </Tooltip>
    ) : (
      <CircularProgress size={24} />
    )}
    <span style={{ marginRight: 8 }}>
      <DeleteTaskIcon task={task} onTaskRemove={onTaskRemove} />
    </span>

    <CompleteTaskIcon task={task} onTaskComplete={completeTask} />
  </>
);
