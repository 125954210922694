import React from 'react';
import { Router } from './router/Router';
import { MuiThemeProvider, styled } from '@material-ui/core/styles';
import { theme } from './styles/theme';
import { CssBaseline } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';

const Container = styled('div')(() => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  minHeight: 'calc(100vh - 70px)',
}));

export const App = () => (
  <MuiThemeProvider theme={theme}>
    <Container>
      <CssBaseline />

      <Router />

      <CookieConsent
        location="bottom"
        buttonText="OK"
        style={{ background: '#2b373b' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      >
        This website uses cookies to enhance user experience, by using the
        website you agree with it.
      </CookieConsent>
    </Container>
  </MuiThemeProvider>
);
