import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import {
  TaskDependencyUnlockAction,
  unlockTaskDependency,
} from '../actions/taskDependencyUnlock';

type TaskDependencyUnlockState = ApiSingleData<Task>;
const initialState: TaskDependencyUnlockState = createDefaultSingleApiData();

export const taskDependencyUnlock = (
  state: TaskDependencyUnlockState = initialState,
  action: TaskDependencyUnlockAction
): TaskDependencyUnlockState => {
  switch (action.type) {
    case getType(unlockTaskDependency.request):
      return {
        ...state,
        loading: true,
      };

    case getType(unlockTaskDependency.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(unlockTaskDependency.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
