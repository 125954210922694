import React, { FC, useCallback, useEffect } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { useForm } from 'react-hook-form';
import { validationRequired } from '../../services/form/form';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import { createCategoryApiRequest } from '../../store/actions/categoryCreate';
import { Category, FormData } from '../../types/category';
import { updateCategoryApiRequest } from '../../store/actions/categoryUpdate';
import { clearCategoryState } from '../../store/actions/category';
import { FormTextField } from '../form/FormTextField';
import { SubmitFormDialog } from '../form/SubmitFormDialog';
import { FormColorField } from '../form/FormColorField';
import { colors } from '../../constants';

interface Props {
  isOpen: boolean;
  projectUuid: string;
  category?: Category;
  categoryLoading?: boolean;
}

const createFormData = (category?: Category): FormData => ({
  name: category?.name || '',
  description: category?.description || '',
});

export const CategoryFormDialog: FC<Props> = ({
  isOpen,
  projectUuid,
  category,
  categoryLoading,
}) => {
  const { closeDialog } = useDialog();

  const { register, errors, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: createFormData(),
  });

  const { loading: isCreateSubmitting } = useSelector(
    (state) => state.categoryCreate
  );
  const { loading: isUpdateSubmitting } = useSelector(
    (state) => state.categoryUpdate
  );

  const dispatch = useDispatch();

  const setValues = useCallback(
    (formData: FormData) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    },
    [setValue]
  );

  const onSubmit = (formData: FormData) => {
    !!category
      ? dispatch(updateCategoryApiRequest(category.uuid, projectUuid, formData))
      : dispatch(createCategoryApiRequest(projectUuid, formData));
  };

  useEffect(() => {
    if (!isOpen) {
      dispatch(clearCategoryState());
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (category) {
      setValues(createFormData(category));
    }
  }, [category, setValues]);

  return (
    <SubmitFormDialog
      isOpen={isOpen}
      isSubmitting={isCreateSubmitting || isUpdateSubmitting}
      isLoading={categoryLoading}
      title={
        !!category || categoryLoading
          ? 'Update category'
          : 'Create new category'
      }
      onSubmit={handleSubmit(onSubmit)}
      onDialogClose={closeDialog}
    >
      <FormTextField
        name="name"
        label="Category name"
        register={register}
        errors={errors}
        validationOptions={{ ...validationRequired() }}
        style={{ marginBottom: 16 }}
        autoFocus
        fullWidth
      />

      <FormTextField
        name="description"
        label="Category description"
        register={register}
        errors={errors}
        fullWidth
        style={{ marginBottom: 32 }}
      />

      <FormColorField
        name="color"
        label="Category color (optional, if not selected a random color will be chosen)"
        register={register}
        errors={errors}
        setValue={setValue}
        initialValue={category?.color}
        options={colors.map((color) => ({ hex: color }))}
      />
    </SubmitFormDialog>
  );
};
