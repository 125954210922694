import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getInvitationsApiRequest } from './invitations';
import { getProjectsApiRequest } from './projects';

export const acceptInvitationApiRequest = (uuid: string) =>
  createApiAction(
    apiUrl(`invitations/${uuid}/accept`),
    '@invitation/ACCEPT_INVITATION',
    'PUT',
    {
      onSuccess: [
        () => getInvitationsApiRequest(),
        () => getProjectsApiRequest(),
      ],
    }
  );

export const acceptInvitation = createAsyncAction(
  '@invitation/ACCEPT_INVITATION_REQUEST',
  '@invitation/ACCEPT_INVITATION_SUCCESS',
  '@invitation/ACCEPT_INVITATION_FAILURE'
)<undefined, undefined, ApiError>();

export type InvitationAcceptAction = ActionType<typeof acceptInvitation>;
