import React, { FC } from 'react';
import { nbsp } from '../services/strings/strings';
import { UserAvatar } from './UserAvatar';

const AVATAR_SIZE = 28;

interface Props {
  username: string;
  color: string;
}

export const UserNameAvatar: FC<Props> = ({ username, color }) => (
  <div style={{ width: AVATAR_SIZE }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserAvatar username={username} color={color} />
      <span dangerouslySetInnerHTML={{ __html: nbsp(username) }} />
    </div>
  </div>
);
