import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Task } from '../../types/task';
import { getTasksApiRequest } from './tasks';
import { getTaskApiRequest } from './task';

export const removeTaskApiRequest = (uuid: string, projectUuid: string) =>
  createApiAction(apiUrl(`tasks/${uuid}`), '@task/REMOVE_TASK', 'DELETE', {
    onSuccess: [
      () => getTasksApiRequest(projectUuid),
      () => getTaskApiRequest(uuid),
    ],
  });

export const removeTask = createAsyncAction(
  '@task/REMOVE_TASK_REQUEST',
  '@task/REMOVE_TASK_SUCCESS',
  '@task/REMOVE_TASK_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskRemoveAction = ActionType<typeof removeTask>;
