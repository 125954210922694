import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData } from '../../types/invitation';
import { closeDialog } from './dialog';

export const createInvitationApiRequest = (
  projectUuid: string,
  formData: FormData
) =>
  createApiAction(
    apiUrl('invitations'),
    '@invitation/CREATE_INVITATION',
    'POST',
    {
      data: { projectUuid, ...formData },
      onSuccess: [() => closeDialog()],
    }
  );

export const createInvitation = createAsyncAction(
  '@invitation/CREATE_INVITATION_REQUEST',
  '@invitation/CREATE_INVITATION_SUCCESS',
  '@invitation/CREATE_INVITATION_FAILURE'
)<undefined, undefined, ApiError>();

export type InvitationCreateAction = ActionType<typeof createInvitation>;
