import { Task } from '../../types/task';

interface TaskFilter {
  hideCompletedTasks: boolean;
  noDependencyTasks: boolean;
  fulltextQuery: string;
  assignedUsers: string[];
}

export const isTaskDone = (task: Task | null) => task?.status === 'done';
export const isTaskHighPriority = (task: Task | null) => task?.priority === 1;
const isTaskWithDependecy = (task: Task | null) => !!task?.dependency;
const isTaskAssignedToUser = (task: Task | null, userUuids: string[]) =>
  !userUuids ||
  userUuids.length === 0 ||
  userUuids.includes(task?.assignedUser?.uuid || '');

export const filterTasks = (
  tasks: Task[] | null,
  columnLayout: boolean,
  {
    hideCompletedTasks,
    noDependencyTasks,
    fulltextQuery,
    assignedUsers,
  }: TaskFilter
) =>
  (tasks || []).filter(
    (task) =>
      (columnLayout || !hideCompletedTasks || !isTaskDone(task)) &&
      (!noDependencyTasks || !isTaskWithDependecy(task)) &&
      isTaskAssignedToUser(task, assignedUsers) &&
      (!fulltextQuery ||
        fulltextQuery.length < 2 ||
        task.name.toLowerCase().includes(fulltextQuery.toLowerCase()))
  );
