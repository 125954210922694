import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckboxFieldProps } from '../../types/form';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';

export const FormCheckboxField = <FormValues extends any>({
  register,
  name,
  validationOptions,
  label,
  defaultChecked,
  setValue,
  ...props
}: CheckboxProps & CheckboxFieldProps<FormValues>) => {
  const [currentChecked, setCurrentChecked] = useState<boolean>(false);

  const changeValue = useCallback(
    (checked: boolean) => {
      setCurrentChecked(checked);
      setValue(name, checked);
    },
    [name, setValue]
  );

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    if (defaultChecked !== undefined) {
      changeValue(defaultChecked);
    }
  }, [changeValue, defaultChecked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          name={name}
          color="primary"
          checked={currentChecked}
          onChange={() => {
            changeValue(!currentChecked);
          }}
        />
      }
      label={label}
    />
  );
};
