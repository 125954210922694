import React from 'react';

import { DataGridEmptyRow } from './DataGridEmptyRow';
import { ScrollableTable } from '../ScrollableTable';
import { DataGridColumn, DataGridRowType } from '../../types/dataGrid';
import { DataGridHeader } from './DataGridHeader';
import { DataGridLoading } from './DataGridLoading';
import { DataGridRow } from './DataGridRow';
import TableBody from '@material-ui/core/TableBody';

interface Props<T> {
  columns: Array<DataGridColumn<T>>;
  data: DataGridRowType[];
  isLoading: boolean;
  noDataMessage?: string | React.ReactNode;
}

export const DataGrid = <T extends any>({
  data,
  columns,
  isLoading,
  noDataMessage,
}: Props<T>) => (
  <div>
    {/*<GroupedErrorsList errors={apiErrors} />*/}

    <ScrollableTable>
      <DataGridHeader columns={columns} />

      {!isLoading && data.length > 0 && (
        <TableBody>
          {data.map((row: DataGridRowType, i: number) => (
            <DataGridRow index={i} key={i} row={row} columns={columns} />
          ))}
        </TableBody>
      )}

      {!isLoading && !data.length && (
        <DataGridEmptyRow
          columnsCount={columns.length}
          message={noDataMessage}
        />
      )}

      <DataGridLoading isLoading={isLoading} columnsCount={columns.length} />
    </ScrollableTable>
  </div>
);
