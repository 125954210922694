import { authTokenStorage } from '../services/authToken/authToken';
import { useDispatch } from 'react-redux';
import { resetStore } from '../store/actions/reset';

export const useLogout = () => {
  const dispatch = useDispatch();

  return () => {
    authTokenStorage.clearToken();

    dispatch(resetStore());
  };
};
