import React, { useEffect } from 'react';
import { Paper, styled, Typography } from '@material-ui/core';
import { StyledLink } from '../../components/StyledLink';
import { routes } from '../../routes/routes';
import { CenteredText } from '../../components/CenteredText';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import {
  clearUserSignupState,
  signupUserApiRequest,
} from '../../store/actions/user';
import DoneIcon from '@material-ui/icons/Done';
import { SignupForm } from './SignupForm';
import { ApiError } from '../../types/api';
import { PublicPageContainer } from '../../components/layout/PublicPageContainer';
import { ServerErrorMessage } from '../../components/layout/ServerErrorMessage';
import { useTitle } from '../../library/hooks/useTitle';

const isEmailExistsError = (error: ApiError | null) =>
  error?.status === 422 &&
  !!error.messages &&
  !!error.messages.find((message) => message.context === 'username');

const FormWrapper = styled(Paper)(({ theme }) => ({
  width: 360,
  padding: theme.spacing(3),
}));

interface FormData {
  name: string;
  username: string;
  password: string;
  passwordConfirmation: string;
}

export const SignupPage = () => {
  useTitle('Sign Up');

  const dispatch = useDispatch();

  const { data: userSignupData, loading, error } = useSelector(
    (state) => state.userSignup
  );

  const emailExistsError = isEmailExistsError(error);

  const onSubmit = ({ name, username, password }: FormData) => {
    dispatch(signupUserApiRequest(name, username, password));
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserSignupState());
    };
  }, [dispatch]);

  return (
    <PublicPageContainer>
      <FormWrapper>
        <CenteredText>
          <Typography variant="h5" gutterBottom>
            Sign Up
          </Typography>
        </CenteredText>

        <ServerErrorMessage />

        {!!userSignupData ? (
          <CenteredText>
            <div style={{ marginBottom: 16 }}>
              Welcome to {process.env.REACT_APP_APP_NAME}!
            </div>

            <DoneIcon
              color="primary"
              style={{ fontSize: 180, display: 'block', margin: 'auto' }}
            />

            <StyledLink to={routes.login}>Continue to login</StyledLink>
          </CenteredText>
        ) : (
          <SignupForm
            loading={loading}
            emailExistsError={emailExistsError}
            onSubmit={onSubmit}
          />
        )}
      </FormWrapper>
    </PublicPageContainer>
  );
};
