export const MIN_PASSWORD_LENGTH = 6;

export const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#0097a7',
  '#009688',
  '#43a047',
  '#689f38',
  '#827717',
  '#ffc107',
  '#ef6c00',
  '#ff5722',
  '#795548',
  '#607d8b',
];
