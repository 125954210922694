import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Task } from '../../types/task';
import { getTasksApiRequest } from './tasks';
import { closeDialog } from './dialog';
import { getTaskApiRequest } from './task';

export const updateTaskApiRequest = (
  uuid: string,
  projectUuid: string,
  formData: FormData
) =>
  createApiAction(apiUrl(`tasks/${uuid}`), '@task/UPDATE_TASK', 'PUT', {
    data: formData,
    onSuccess: [
      () => closeDialog(),
      () => getTasksApiRequest(projectUuid),
      () => getTaskApiRequest(uuid),
    ],
  });

export const updateTask = createAsyncAction(
  '@task/UPDATE_TASK_REQUEST',
  '@task/UPDATE_TASK_SUCCESS',
  '@task/UPDATE_TASK_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskUpdateAction = ActionType<typeof updateTask>;
