import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Task } from '../../types/task';

export const getTaskApiRequest = (uuid: string) =>
  createApiAction(apiUrl(`tasks/${uuid}`), '@task/GET_TASK', 'GET');

export const getTask = createAsyncAction(
  '@task/GET_TASK_REQUEST',
  '@task/GET_TASK_SUCCESS',
  '@task/GET_TASK_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export const clearTaskState = createAction('@task/CLEAR_TASK_STATE')();

export type TaskAction = ActionType<typeof getTask | typeof clearTaskState>;
