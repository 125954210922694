import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getTasksApiRequest } from './tasks';
import { Task } from '../../types/task';
import { getTaskApiRequest } from './task';

export const toggleTaskHighPriorityApiRequest = (
  uuid: string,
  projectUuid: string
) =>
  createApiAction(
    apiUrl(`tasks/${uuid}/high-priority`),
    '@task/TOGGLE_HIGH_PRIORITY',
    'PUT',
    {
      onSuccess: [
        () => getTasksApiRequest(projectUuid),
        () => getTaskApiRequest(uuid),
      ],
    }
  );

export const toggleTaskHighPriority = createAsyncAction(
  '@task/TOGGLE_HIGH_PRIORITY_REQUEST',
  '@task/TOGGLE_HIGH_PRIORITY_SUCCESS',
  '@task/TOGGLE_HIGH_PRIORITY_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskToggleHighPriorityAction = ActionType<
  typeof toggleTaskHighPriority
>;
