import React from 'react';
import { useUser } from '../../hooks/useUser';
import { UserProfilePageContainer } from '../../components/layout/UserProfilePageContainer';

export const UserProfilePage = () => {
  const { user } = useUser();

  return (
    <UserProfilePageContainer activeTab="profile">
      <table>
        <tbody>
          <tr>
            <th>Username:</th>
            <td>{user?.name}</td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left' }}>E-mail:</th>
            <td>{user?.username}</td>
          </tr>
        </tbody>
      </table>
    </UserProfilePageContainer>
  );
};
