import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getCategoriesApiRequest } from './categories';

export const removeCategoryApiRequest = (uuid: string, projectUuid: string) =>
  createApiAction(
    apiUrl(`categories/${uuid}`),
    '@category/REMOVE_CATEGORY',
    'DELETE',
    {
      onSuccess: [() => getCategoriesApiRequest(projectUuid)],
    }
  );

export const removeCategory = createAsyncAction(
  '@category/REMOVE_CATEGORY_REQUEST',
  '@category/REMOVE_CATEGORY_SUCCESS',
  '@category/REMOVE_CATEGORY_FAILURE'
)<undefined, undefined, ApiError>();

export const clearCategoryRemoveState = createAction(
  '@category/CLEAR_CATEGORY_REMOVE_STATE'
)();

export type CategoryRemoveAction = ActionType<
  typeof removeCategory | typeof clearCategoryRemoveState
>;
