import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { AutocompleteFieldProps, SelectOption } from '../../types/form';
import { UseAutocompleteProps } from '@material-ui/lab/useAutocomplete';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { usePrevious } from '../../library/hooks/usePrevious';

export const FormAutocompleteField = <FormValues extends any>({
  register,
  name,
  validationOptions,
  label,
  options,
  setValue,
  initialValue,
  watch,
  ...props
}: Omit<
  AutocompleteProps<SelectOption> & UseAutocompleteProps<SelectOption>,
  'renderInput'
> &
  AutocompleteFieldProps<FormValues>) => {
  const [currentValue, setCurrentValue] = useState<SelectOption | null>(null);

  const value = watch(name);

  const previousInitialValue = usePrevious(initialValue);

  const serializedOptions = JSON.stringify(options);

  const getOption = useCallback(
    (key?: SelectOption['key']) =>
      options.find((option) => option.key === key) || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serializedOptions]
  );

  const changeValue = useCallback(
    (value: SelectOption | null) => {
      setCurrentValue(value);
      setValue(name, value?.key);
    },
    [name, setValue]
  );

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    if (previousInitialValue !== initialValue) {
      changeValue(getOption(initialValue));
    }
  }, [changeValue, getOption, initialValue, previousInitialValue]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setCurrentValue(getOption(value));
    }
  }, [getOption, value]);

  const autocomplete = (
    // @ts-ignore
    <Autocomplete
      {...props}
      value={currentValue}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.key === value.key}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(event: any, newValue: SelectOption | null) => {
        changeValue(newValue);
      }}
      fullWidth
    />
  );

  return autocomplete;
};
