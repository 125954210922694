import React, { useCallback, useEffect, useState } from 'react';
import { ColorFieldProps, ColorOption } from '../../types/form';
import { StyledAvatar } from '../StyledAvatar';
import { Grid } from '@material-ui/core';

export const FormColorField = <FormValues extends any>({
  register,
  name,
  validationOptions,
  options,
  setValue,
  initialValue,
  label,
  ...props
}: ColorFieldProps<FormValues>) => {
  const [currentValue, setCurrentValue] = useState<ColorOption['hex'] | null>(
    null
  );

  const changeValue = useCallback(
    (value: ColorOption['hex'] | null) => {
      setCurrentValue(value);
      setValue(name, value);
    },
    [name, setValue]
  );

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    changeValue((initialValue as string) || null);
  }, [initialValue, changeValue, options]);

  return (
    <>
      {label && (
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '1rem',
            marginBottom: 8,
          }}
        >
          {label}
        </div>
      )}
      <Grid container>
        {options.map(({ hex }) => (
          <StyledAvatar
            key={hex}
            color={hex}
            style={{
              width: 28,
              height: 28,
              fontSize: 14,
              marginRight: 8,
              border: '2px solid #fff',
              boxShadow: `0 0 ${currentValue === hex ? '2px' : 0} #000`,
              cursor: 'pointer',
            }}
            onClick={() => {
              changeValue(hex);
            }}
            {...props}
          >
            {' '}
          </StyledAvatar>
        ))}
      </Grid>
    </>
  );
};
