import React, { FC } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import { StyledButton } from '../StyledButton';
import { ContentDialog } from './ContentDialog';

interface Props {
  isOpen: boolean;
  title: string;
  isSubmitting?: boolean;
  isLoading?: boolean;
  submitCaption?: string;
  onDialogClose: () => void;
  onSubmit: () => void;
}

export const SubmitFormDialog: FC<Props> = ({
  onDialogClose,
  onSubmit,
  isOpen,
  title,
  isSubmitting,
  children,
  isLoading,
  submitCaption,
}) => (
  <ContentDialog
    isOpen={isOpen}
    onDialogClose={onDialogClose}
    title={title}
    isLoading={isLoading}
  >
    <form onSubmit={onSubmit}>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <StyledButton type="submit" isSubmitting={isSubmitting}>
          {submitCaption || 'Save'}
        </StyledButton>
      </DialogActions>
    </form>
  </ContentDialog>
);
