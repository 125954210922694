import { Storage } from '../../types/storage';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';

interface AuthTokenStorage {
  storeToken: (value: string) => void;
  loadToken: () => string | null;
  clearToken: () => void;
}

export const createAuthTokenStorage = (
  authTokenStorage: Storage
): AuthTokenStorage => ({
  storeToken: (value: string) =>
    authTokenStorage.set(AUTH_TOKEN_STORAGE_KEY, value),
  loadToken: () => authTokenStorage.get(AUTH_TOKEN_STORAGE_KEY),
  clearToken: () => authTokenStorage.clear(AUTH_TOKEN_STORAGE_KEY),
});
