import React, { FC } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell } from '../../components/table/StyledTableCell';
import { DataGridEmptyRow } from '../../components/dataGrid/DataGridEmptyRow';
import { DataGridLoading } from '../../components/dataGrid/DataGridLoading';
import TableBody from '@material-ui/core/TableBody';
import { TaskInlineUpdateForm } from './TaskInlineUpdateForm';
import { StyledTableRow } from '../../components/table/StyledTableRow';
import { UserNameAvatar } from '../../components/UserNameAvatar';
import { TaskStatusBadge } from './TaskStatusBadge';
import { Task } from '../../types/task';
import { TaskDependency } from './TaskDependency';
import { routes } from '../../routes/routes';
import { StyledLink } from '../../components/StyledLink';
import { CategoryBadgeList } from '../../components/category/CategoryBadgeList';
import { TaskActions } from '../../components/task/TaskActions';

interface Props {
  filteredTasks: Task[];
  tasksLoading: boolean;
  selectedTaskUuid: string | null;
  selectedTaskLoading: boolean;
  projectUuid: string;
  selectedTask: Task | null;
  setSelectedTaskUuid: (taskUuid: string | null) => void;
  editTask: (taskUuid: string) => void;
  unlockDependency: (taskUuid: string) => void;
  toggleTaskHighPriority: (taskUuid: string) => void;
  onTaskRemove: (taskUuid: string) => void;
  completeTask: (taskUuid: string) => void;
}

export const TaskList: FC<Props> = ({
  projectUuid,
  tasksLoading,
  selectedTaskUuid,
  setSelectedTaskUuid,
  filteredTasks,
  selectedTask,
  selectedTaskLoading,
  unlockDependency,
  toggleTaskHighPriority,
  onTaskRemove,
  completeTask,
  editTask,
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Task name</StyledTableCell>
          <StyledTableCell align="center">Category</StyledTableCell>
          <StyledTableCell>Assigned</StyledTableCell>
          {/*<StyledTableCell>Description</StyledTableCell>*/}
          <StyledTableCell>Dependency</StyledTableCell>
          <StyledTableCell align="center">Status</StyledTableCell>
          <StyledTableCell align="right">Actions</StyledTableCell>
        </TableRow>
      </TableHead>
      {!tasksLoading && filteredTasks.length === 0 && (
        <DataGridEmptyRow columnsCount={8} />
      )}

      <DataGridLoading
        isLoading={tasksLoading && filteredTasks.length === 0}
        columnsCount={8}
      />

      {(!tasksLoading || filteredTasks.length > 0) && (
        <TableBody>
          {filteredTasks.map((task) => {
            if (
              selectedTaskUuid === task.uuid &&
              !!selectedTask &&
              !selectedTaskLoading
            ) {
              return (
                <TaskInlineUpdateForm
                  key={task.uuid}
                  projectUuid={projectUuid}
                  task={selectedTask}
                  onCancel={() => {
                    setSelectedTaskUuid(null);
                  }}
                />
              );
            } else {
              return (
                <StyledTableRow hover key={task.uuid}>
                  <StyledTableCell scope="row">
                    <StyledLink to={routes.taskDetail(projectUuid, task.uuid)}>
                      {task.name}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CategoryBadgeList categories={task.categories} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {!!task.assignedUser && (
                      <UserNameAvatar
                        username={task.assignedUser.name}
                        color={task.assignedUser.avatarColor}
                      />
                    )}
                  </StyledTableCell>
                  {/*<StyledTableCell>{task.description}</StyledTableCell>*/}
                  <StyledTableCell>
                    <TaskDependency
                      task={task}
                      unlockDependency={unlockDependency}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TaskStatusBadge status={task.status} />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TaskActions
                      task={task}
                      selectedTaskUuid={selectedTaskUuid}
                      selectedTaskLoading={selectedTaskLoading}
                      editTask={editTask}
                      toggleTaskHighPriority={toggleTaskHighPriority}
                      onTaskRemove={onTaskRemove}
                      completeTask={completeTask}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            }
          })}
        </TableBody>
      )}
    </Table>
  </TableContainer>
);
