import React from 'react';
import { withStyles, Avatar, AvatarProps } from '@material-ui/core';

interface Props {
  classes: any;
  size?: number;
}

const styles = () => ({
  customAvatar: {
    backgroundColor: (props: { color: string }) => props.color,
    color: '#fff',
  },
});

const ColoredAvatar = ({
  classes,
  children,
  ...props
}: Props & AvatarProps) => (
  <Avatar classes={{ circle: classes.customAvatar }} {...props}>
    {children}
  </Avatar>
);

export const StyledAvatar = withStyles(styles)(ColoredAvatar);
