import { getType } from 'typesafe-actions';
import { closeDialog, DialogAction, openDialog } from '../actions/dialog';

type DialogState = { openedDialogs: string[] };
const initialState: DialogState = { openedDialogs: [] };

export const dialog = (
  state: DialogState = initialState,
  action: DialogAction
): DialogState => {
  switch (action.type) {
    case getType(openDialog):
      return {
        ...state,
        openedDialogs: [...state.openedDialogs, ...[action.payload.title]],
      };
    case getType(closeDialog):
      return {
        ...state,
        openedDialogs: state.openedDialogs.slice(0, -1),
      };
    default:
      return state;
  }
};
