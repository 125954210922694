import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { removeProject, ProjectRemoveAction } from '../actions/projectRemove';

type ProjectRemoveState = ApiSingleData<undefined>;
const initialState: ProjectRemoveState = createDefaultSingleApiData();

export const projectRemove = (
  state: ProjectRemoveState = initialState,
  action: ProjectRemoveAction
): ProjectRemoveState => {
  switch (action.type) {
    case getType(removeProject.request):
      return {
        ...state,
        loading: true,
      };

    case getType(removeProject.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(removeProject.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
