import React, { FC, useCallback, useEffect } from 'react';
import { FormData, Task } from '../../types/task';
import { FormTextField } from '../form/FormTextField';
import { createTaskApiRequest } from '../../store/actions/taskCreate';
import { useDispatch } from 'react-redux';
import { SubmitFormDialog } from '../form/SubmitFormDialog';
import { selectOption, validationRequired } from '../../services/form/form';
import { useForm } from 'react-hook-form';
import { updateTaskApiRequest } from '../../store/actions/taskUpdate';
import { useSelector } from '../../hooks/useSelector';
import { FormMultipleAutocompleteField } from '../form/FormMultipleAutocompleteField';
import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FormAutocompleteField } from '../form/FormAutocompleteField';
import { FormSelectField } from '../form/FormSelectField';
import { taskStatusOptions } from '../../pages/tasks/form';
import { useDialog } from '../../hooks/useDialog';
import { clearTaskState } from '../../store/actions/task';
import { createApiPayload, createFormData } from '../../utils/task/taskForm';

interface Props {
  projectUuid: string;
  isOpen: boolean;
  task?: Task;
  taskLoading?: boolean;
}

export const TaskFormDialog: FC<Props> = ({
  projectUuid,
  task,
  taskLoading,
  isOpen,
}) => {
  const { openDialog, closeDialog } = useDialog();

  const { register, errors, handleSubmit, setValue, watch } = useForm<FormData>(
    {
      defaultValues: createFormData(),
    }
  );

  const { loading: isCreateSubmitting } = useSelector(
    (state) => state.taskCreate
  );
  const { loading: isUpdateSubmitting } = useSelector(
    (state) => state.taskUpdate
  );

  const { data: projectUsers } = useSelector((state) => state.users);
  const { data: categories } = useSelector((state) => state.categories);

  const dispatch = useDispatch();

  const setValues = useCallback(
    (formData: FormData) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    },
    [setValue]
  );

  const closeTaskDialog = () => {
    closeDialog();
    dispatch(clearTaskState());
  };

  const onSubmit = (formData: FormData) => {
    const apiPayload = createApiPayload(formData);

    if (!!task) {
      dispatch(updateTaskApiRequest(task.uuid, projectUuid, apiPayload));
    } else {
      dispatch(createTaskApiRequest(projectUuid, apiPayload));
    }

    closeTaskDialog();
  };

  useEffect(() => {
    if (task) {
      setValues(createFormData(task));
    }
  }, [task, setValues]);

  const userOptions = (projectUsers || []).map(({ uuid, name }) =>
    selectOption(uuid, name)
  );

  return (
    <SubmitFormDialog
      isOpen={isOpen}
      isSubmitting={isCreateSubmitting || isUpdateSubmitting}
      isLoading={taskLoading}
      title={!!task || taskLoading ? 'Update task' : 'Create new task'}
      onSubmit={handleSubmit(onSubmit)}
      onDialogClose={closeTaskDialog}
    >
      <Box mb={3}>
        <FormTextField
          label="Task name"
          name="name"
          register={register}
          validationOptions={{ ...validationRequired() }}
          errors={errors}
          fullWidth
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center' }} mb={3}>
        <FormMultipleAutocompleteField
          label="Category"
          name="categoryUuids"
          register={register}
          setValue={setValue}
          watch={watch}
          options={(categories || []).map(({ uuid, name }) =>
            selectOption(uuid, name)
          )}
          style={{
            alignSelf: 'center',
            width: 'calc(100% - 40px)',
          }}
        />
        <Fab
          color="secondary"
          size="small"
          onClick={() => {
            openDialog('categoryDialog');
          }}
          style={{
            color: '#fff',
            flexShrink: 0,
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Box mb={3}>
        <FormAutocompleteField
          name="assignedUserUuid"
          label="Assigned user"
          options={userOptions}
          watch={watch}
          setValue={setValue}
          register={register}
          fullWidth
        />
      </Box>
      <Box mb={3}>
        <FormSelectField
          label="Status"
          name="status"
          register={register}
          setValue={setValue}
          defaultValue={task?.status}
          options={taskStatusOptions}
          watch={watch}
          fullWidth
        />
      </Box>
      <Box mb={3}>
        <FormTextField
          label="Description"
          name="description"
          register={register}
          errors={errors}
          rows={4}
          multiline
          fullWidth
        />
      </Box>
    </SubmitFormDialog>
  );
};
