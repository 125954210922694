import { useDispatch } from 'react-redux';
import { useSelector } from '../useSelector';
import { toggleTaskHighPriorityApiRequest } from '../../store/actions/taskToggleHighPriority';

export const useTaskTogglePriority = (projectUuid: string) => {
  const dispatch = useDispatch();

  const { loading: toggleTaskHighPrioritySubmitting } = useSelector(
    (state) => state.taskToggleHighPriority
  );

  const toggleTaskHighPriority = (uuid: string) => {
    if (!toggleTaskHighPrioritySubmitting) {
      dispatch(toggleTaskHighPriorityApiRequest(uuid, projectUuid));
    }
  };

  return {
    toggleTaskHighPriority,
  };
};
