import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProjectUsersApiRequest } from '../../store/actions/users';
import { useSelector } from '../../hooks/useSelector';
import { TaskCreateForm } from './TaskCreateForm';
import { getCategoriesApiRequest } from '../../store/actions/categories';
import { useParams } from 'react-router-dom';
import { getTasksApiRequest } from '../../store/actions/tasks';
import { useDialog } from '../../hooks/useDialog';
import { CategoryFormDialog } from '../../components/category/CategoryFormDialog';
import { clearTaskState, getTaskApiRequest } from '../../store/actions/task';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { TaskFilter } from './TaskFilter';
import { TaskCounts } from './TaskCounts';
import { filterTasks } from '../../utils/task/taskFilter';
import { AlertDialog } from '../../AlertDialog';
import { unlockTaskDependencyApiRequest } from '../../store/actions/taskDependencyUnlock';
import { TaskList } from './TaskList';
import { ColumnTaskList } from './ColumnTaskList';
import { useTaskListLayout } from './hooks/useTaskListLayout';
import { ProjectPageContainer } from '../../components/layout/ProjectPageContainer';
import { TaskFormDialog } from '../../components/task/TaskForm';
import { useTaskComplete } from '../../hooks/task/useTaskComplete';
import { useKeyPress } from '../../library/hooks/useKeyPress';
import { useTaskRemove } from '../../hooks/task/useTaskRemove';
import { useTaskTogglePriority } from '../../hooks/task/useTaskTogglePriority';

interface FilterFormData {
  hideCompletedTasks: boolean;
  noDependencyTasks: boolean;
  fulltextQuery: string;
  assignedUsers: string[];
}

export const TasksPage = () => {
  const [selectedTaskUuid, setSelectedTaskUuid] = useState<string | null>(null);
  const { columnLayout, toggleColumnLayout } = useTaskListLayout();

  const { register, watch, setValue } = useForm<FilterFormData>();

  const hideCompletedTasks = watch('hideCompletedTasks');
  const noDependencyTasks = watch('noDependencyTasks');
  const fulltextQuery = watch('fulltextQuery');
  const assignedUsers = watch('assignedUsers');

  const dispatch = useDispatch();

  const { data: tasks, loading: tasksLoading } = useSelector(
    (state) => state.tasks
  );
  const { data: updateTaskData, loading: updateTaskSubmitting } = useSelector(
    (state) => state.taskUpdate
  );
  const { data: selectedTask, loading: selectedTaskLoading } = useSelector(
    (state) => state.task
  );

  const { loading: dependecyUnlockSubmitting } = useSelector(
    (state) => state.taskDependencyUnlock
  );
  const { data: projectUsers } = useSelector((state) => state.users);

  const { projectUuid } = useParams<{ projectUuid: string }>();

  const { isDialogOpen } = useDialog();

  const { removeTask, onTaskRemove, closeRemoveDialog } = useTaskRemove(
    projectUuid
  );
  const { completeTask } = useTaskComplete(projectUuid);
  const { toggleTaskHighPriority } = useTaskTogglePriority(projectUuid);

  const editTask = (uuid: string) => {
    if (!updateTaskSubmitting) {
      dispatch(getTaskApiRequest(uuid));
      setSelectedTaskUuid(uuid);
    }
  };

  // const onEditTaskForm = (uuid: string) => {
  //   dispatch(getTaskApiRequest(uuid));
  //   openDialog('taskDialog');
  // };

  const unlockDependency = (uuid: string) => {
    if (!dependecyUnlockSubmitting) {
      dispatch(unlockTaskDependencyApiRequest(uuid, projectUuid));
    }
  };

  const filteredTasks = useMemo(
    () =>
      filterTasks(tasks, columnLayout, {
        fulltextQuery,
        hideCompletedTasks,
        noDependencyTasks,
        assignedUsers,
      }),
    [
      fulltextQuery,
      hideCompletedTasks,
      noDependencyTasks,
      assignedUsers,
      tasks,
      columnLayout,
    ]
  );

  useKeyPress('Escape', () => {
    setSelectedTaskUuid(null);
  });

  useEffect(() => {
    dispatch(getProjectUsersApiRequest(projectUuid));
    dispatch(getCategoriesApiRequest(projectUuid));
    dispatch(getTasksApiRequest(projectUuid));
  }, [dispatch, projectUuid]);

  useEffect(() => {
    if (!!updateTaskData) {
      setSelectedTaskUuid(null);
    }
  }, [updateTaskData, dispatch]);

  useEffect(() => {
    if (!selectedTaskUuid) {
      dispatch(clearTaskState());
    }
  }, [selectedTaskUuid, dispatch]);

  return (
    <ProjectPageContainer activeTab="tasks">
      <div style={{ marginBottom: 32 }}>
        <TaskCreateForm projectUuid={projectUuid} />
      </div>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 16 }}
      >
        <Grid item>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={columnLayout}
                    onChange={(e) => {
                      toggleColumnLayout(e.target.checked);
                    }}
                    name="columnLayout"
                    color="primary"
                  />
                }
                label="Column layout"
              />
            </Grid>
            <Grid item>
              <TaskFilter
                register={register}
                setValue={setValue}
                projectUsers={projectUsers || []}
              />
            </Grid>
          </Grid>
        </Grid>
        {tasks !== null && (
          <Grid item>
            <TaskCounts tasks={tasks} />
          </Grid>
        )}
      </Grid>

      {columnLayout ? (
        <ColumnTaskList
          tasks={filteredTasks}
          tasksLoading={tasksLoading}
          unlockDependency={unlockDependency}
          toggleTaskHighPriority={toggleTaskHighPriority}
          onTaskRemove={onTaskRemove}
          completeTask={completeTask}
        />
      ) : (
        <TaskList
          filteredTasks={filteredTasks}
          tasksLoading={tasksLoading}
          selectedTaskUuid={selectedTaskUuid}
          selectedTaskLoading={selectedTaskLoading}
          projectUuid={projectUuid}
          selectedTask={selectedTask}
          setSelectedTaskUuid={setSelectedTaskUuid}
          editTask={editTask}
          unlockDependency={unlockDependency}
          toggleTaskHighPriority={toggleTaskHighPriority}
          onTaskRemove={onTaskRemove}
          completeTask={completeTask}
        />
      )}

      <AlertDialog
        title="Remove task"
        text="Do you really want to remove selected task?"
        onDialogClose={closeRemoveDialog}
        isOpen={isDialogOpen('removeTaskDialog')}
        onProceed={removeTask}
      />

      <CategoryFormDialog
        isOpen={isDialogOpen('categoryDialog')}
        projectUuid={projectUuid}
      />

      <TaskFormDialog
        projectUuid={projectUuid}
        isOpen={isDialogOpen('taskDialog')}
        task={selectedTask || undefined}
        taskLoading={selectedTaskLoading}
      />
    </ProjectPageContainer>
  );
};
