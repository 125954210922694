import { useSelector } from './useSelector';

export const useUser = () => {
  const { data: user, error: userError, loading: isUserLoading } = useSelector(
    (state) => state.user
  );

  return {
    user,
    userError,
    isUserLoading,
    isAuthenticated: user !== null,
  };
};
