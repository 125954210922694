import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { StyledTableRow } from '../table/StyledTableRow';
import { StyledTableCell } from '../table/StyledTableCell';
import { CenteredText } from '../CenteredText';

interface Props {
  columnsCount: number;
  message?: string | React.ReactNode;
}

export const DataGridEmptyRow = ({ columnsCount, message }: Props) => (
  <TableBody>
    <StyledTableRow>
      <StyledTableCell
        colSpan={columnsCount}
        style={{ paddingTop: 15, paddingBottom: 15 }}
      >
        <CenteredText>{message || 'No records found.'}</CenteredText>
      </StyledTableCell>
    </StyledTableRow>
  </TableBody>
);
