import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import { getCategoriesApiRequest } from '../../store/actions/categories';
import { useParams } from 'react-router-dom';
import { getProjectApiRequest } from '../../store/actions/project';
import { useDialog } from '../../hooks/useDialog';
import { CategoryFormDialog } from '../../components/category/CategoryFormDialog';
import { DataGrid } from '../../components/dataGrid/DataGrid';
import { FloatingAddIcon } from '../../components/FloatingAddIcon';
import { createCategoriesGrid } from './grid';
import { getCategoryApiRequest } from '../../store/actions/category';
import { clearCategoryRemoveState } from '../../store/actions/categoryRemove';
import { AlertDialog } from '../../AlertDialog';
import { getFirstErrorMessage } from '../../services/api/apiError';
import { useRemoveCategory } from './hooks/useRemoveCategory';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ProjectPageContainer } from '../../components/layout/ProjectPageContainer';

export const CategoriesPage = () => {
  const dispatch = useDispatch();

  const { data: category, loading: categoryLoading } = useSelector(
    (state) => state.category
  );
  const { data: categories, loading: categoriesLoading } = useSelector(
    (state) => state.categories
  );
  const { error: removeCategoryError } = useSelector(
    (state) => state.categoryRemove
  );

  const { projectUuid } = useParams<{ projectUuid: string }>();

  const { isDialogOpen, openDialog } = useDialog();

  const onCategoryEdit = (uuid: string) => {
    dispatch(getCategoryApiRequest(uuid));
    openDialog('categoryDialog');
  };

  const {
    closeRemoveDialog,
    removeCategory,
    onCategoryRemove,
  } = useRemoveCategory(projectUuid);

  const columns = createCategoriesGrid(onCategoryEdit, onCategoryRemove);

  useEffect(() => {
    dispatch(getProjectApiRequest(projectUuid));
    dispatch(getCategoriesApiRequest(projectUuid));
  }, [dispatch, projectUuid]);

  useEffect(() => {
    return () => {
      dispatch(clearCategoryRemoveState());
    };
  }, [dispatch]);

  return (
    <ProjectPageContainer activeTab="categories">
      {!!removeCategoryError && !!removeCategoryError.messages && (
        <div style={{ marginBottom: 16 }}>
          <ErrorMessage>
            {getFirstErrorMessage(removeCategoryError)}
          </ErrorMessage>
        </div>
      )}

      <DataGrid
        data={categories || []}
        isLoading={categoriesLoading && categories === null}
        columns={columns}
      />

      <CategoryFormDialog
        projectUuid={projectUuid}
        isOpen={isDialogOpen('categoryDialog')}
        category={category || undefined}
        categoryLoading={categoryLoading}
      />

      <AlertDialog
        title="Remove category"
        text="Do you really want to remove selected category?"
        onDialogClose={closeRemoveDialog}
        isOpen={isDialogOpen('removeCategoryDialog')}
        onProceed={removeCategory}
      />

      <FloatingAddIcon
        onClick={() => {
          openDialog('categoryDialog');
        }}
      />
    </ProjectPageContainer>
  );
};
