import { ApiListData } from '../../types/api';
import { createDefaultListApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { User } from '../../types/user';
import { getUsers, UsersAction } from '../actions/users';

type UsersState = ApiListData<User>;
const initialState: UsersState = createDefaultListApiData();

export const users = (
  state: UsersState = initialState,
  action: UsersAction
): UsersState => {
  switch (action.type) {
    case getType(getUsers.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getUsers.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getType(getUsers.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
