import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import {
  removeCategory,
  CategoryRemoveAction,
  clearCategoryRemoveState,
} from '../actions/categoryRemove';

type CategoryRemoveState = ApiSingleData<undefined>;
const initialState: CategoryRemoveState = createDefaultSingleApiData();

export const categoryRemove = (
  state: CategoryRemoveState = initialState,
  action: CategoryRemoveAction
): CategoryRemoveState => {
  switch (action.type) {
    case getType(removeCategory.request):
      return {
        ...state,
        loading: true,
      };

    case getType(removeCategory.success):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getType(removeCategory.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getType(clearCategoryRemoveState):
      return initialState;

    default:
      return state;
  }
};
