import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Fab, styled } from '@material-ui/core';
import { FC } from 'react';

const FloatingIcon = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  color: '#fff',
}));

interface Props {
  onClick: () => void;
}

export const FloatingAddIcon: FC<Props> = ({ onClick }) => (
  <FloatingIcon color="secondary" onClick={onClick}>
    <AddIcon />
  </FloatingIcon>
);
