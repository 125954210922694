import { useSelector } from './useSelector';
import { isNetworkError, isServerError } from '../services/api/apiError';

export const useServerError = () => {
  const state = useSelector((state) => state);

  const serverError = !!Object.keys(state).find(
    (key) =>
      // @ts-ignore
      !!state[key] && !!state[key].error && isServerError(state[key].error)
  );

  const networkError = !!Object.keys(state).find(
    (key) =>
      // @ts-ignore
      !!state[key] && !!state[key].error && isNetworkError(state[key].error)
  );

  return { isServerError: serverError, isNetworkError: networkError };
};
