import React, { FC } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { useDispatch } from 'react-redux';
import { Project } from '../../types/project';
import { createInvitationsGrid } from './grid/invitationsGrid';
import { removeInvitationApiRequest } from '../../store/actions/invitationRemove';
import { DataGrid } from '../../components/dataGrid/DataGrid';
import { ContentDialog } from '../../components/form/ContentDialog';
import { DialogContent, Typography } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  project: Project | null;
  projectLoading: boolean;
}

export const InvitationListDialog: FC<Props> = ({
  isOpen,
  project,
  projectLoading,
}) => {
  const { closeDialog } = useDialog();

  const dispatch = useDispatch();

  const onInvitationRemove = (uuid: string) => {
    dispatch(removeInvitationApiRequest(uuid, project!.uuid));
  };

  const columns = createInvitationsGrid(onInvitationRemove);

  return (
    <ContentDialog
      isOpen={isOpen}
      isLoading={projectLoading && !project}
      title="Invitations & users assigned to project"
      onDialogClose={closeDialog}
      closeButton={true}
    >
      <DialogContent dividers>
        <div style={{ marginBottom: 16 }}>
          <Typography>
            You can remove already sent invitations, removing an invitation will
            also detach the user from the project if the invitation has already
            been accepted.
          </Typography>
        </div>
        <DataGrid
          data={project?.invitations || []}
          isLoading={projectLoading}
          columns={columns}
          noDataMessage={<>There are no team members invited yet.</>}
        />
      </DialogContent>
    </ContentDialog>
  );
};
