import { Dispatch, MiddlewareAPI } from 'redux';
import { authTokenStorage } from '../../services/authToken/authToken';
import { resetStore } from '../actions/reset';
import { setAuthError } from '../actions/authError';
import {
  isAuthError,
  isInvalidCredentialsError,
} from '../../services/api/apiError';

export const authMiddleware = ({ dispatch }: MiddlewareAPI) => (
  next: Dispatch
) => async (action: any) => {
  next(action);

  const actionNameParts = action.type.split('_');

  if (
    actionNameParts[actionNameParts.length - 1] === 'FAILURE' &&
    isAuthError(action?.payload) &&
    !isInvalidCredentialsError(action?.payload)
  ) {
    authTokenStorage.clearToken();

    dispatch(resetStore());

    dispatch(setAuthError(action.payload));
  }
};
