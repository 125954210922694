import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Project } from '../../types/project';
import { createProject, ProjectCreateAction } from '../actions/projectCreate';

type ProjectCreateState = ApiSingleData<Project>;
const initialState: ProjectCreateState = createDefaultSingleApiData();

export const projectCreate = (
  state: ProjectCreateState = initialState,
  action: ProjectCreateAction
): ProjectCreateState => {
  switch (action.type) {
    case getType(createProject.request):
      return {
        ...state,
        loading: true,
      };

    case getType(createProject.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(createProject.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
