import React, { FC } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { ColoredBadge } from '../../components/ColoredBadge';
import { nbsp } from '../../services/strings/strings';
import { TaskDependency } from './TaskDependency';
import { StarTaskIcon } from './actionIcons/StarTaskIcon';
import { DeleteTaskIcon } from './actionIcons/DeleteTaskIcon';
import { CompleteTaskIcon } from './actionIcons/CompleteTaskIcon';
import { Task } from '../../types/task';
import { grey } from '@material-ui/core/colors';
import { TaskStatusBadge } from './TaskStatusBadge';
import { UserNameAvatar } from '../../components/UserNameAvatar';
import { CenteredText } from '../../components/CenteredText';

interface Props {
  tasks: Task[];
  tasksLoading: boolean;
  unlockDependency: (taskUuid: string) => void;
  toggleTaskHighPriority: (taskUuid: string) => void;
  onTaskRemove: (taskUuid: string) => void;
  completeTask: (taskUuid: string) => void;
}

const statuses: Task['status'][] = ['ready', 'in_progress', 'done'];

export const ColumnTaskList: FC<Props> = ({
  tasks,
  tasksLoading,
  completeTask,
  onTaskRemove,
  toggleTaskHighPriority,
  unlockDependency,
}) => {
  if (tasksLoading && tasks.length === 0) {
    return (
      <CenteredText>
        <CircularProgress size={39} />
      </CenteredText>
    );
  }

  return (
    <Grid container justify="center">
      {statuses.map((status, i) => {
        const statusTasks = tasks.filter((task) => task.status === status);

        return (
          <Grid
            item
            key={i}
            style={{
              width: 300,
              marginRight: 32,
              background: grey[200],
              padding: 16,
              borderRadius: 5,
            }}
          >
            <>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: 16 }}
              >
                <Grid item>
                  <TaskStatusBadge status={status} />
                </Grid>
                <Grid item>{statusTasks.length}</Grid>
              </Grid>
              <div style={{ maxHeight: 700, overflow: 'auto' }}>
                {statusTasks.map((task, j) => (
                  <Card style={{ marginBottom: 8 }} key={j}>
                    <CardContent>
                      <div style={{ marginBottom: 8 }}>{task.name}</div>

                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {task.categories.map((category, i) => (
                          <div
                            key={category.uuid}
                            style={{
                              marginRight:
                                i < task.categories.length - 1 ? 8 : 0,
                              marginBottom: 8,
                            }}
                          >
                            <ColoredBadge
                              color={category.color}
                              content={
                                <b
                                  dangerouslySetInnerHTML={{
                                    __html: nbsp(category.name),
                                  }}
                                />
                              }
                            />
                          </div>
                        ))}
                      </div>

                      {!!task.assignedUser && (
                        <div style={{ marginTop: 16 }}>
                          <UserNameAvatar
                            username={task.assignedUser.name}
                            color={task.assignedUser.avatarColor}
                          />
                        </div>
                      )}

                      {!!task.dependency && (
                        <div style={{ marginTop: 16 }}>
                          <TaskDependency
                            task={task}
                            unlockDependency={unlockDependency}
                          />
                        </div>
                      )}
                    </CardContent>
                    <CardActions style={{ padding: 16 }}>
                      <StarTaskIcon
                        task={task}
                        toggleTaskHighPriority={toggleTaskHighPriority}
                      />

                      <DeleteTaskIcon task={task} onTaskRemove={onTaskRemove} />

                      <CompleteTaskIcon
                        task={task}
                        onTaskComplete={completeTask}
                      />
                    </CardActions>
                  </Card>
                ))}
              </div>
            </>
          </Grid>
        );
      })}
    </Grid>
  );
};
