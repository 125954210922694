import { ApiListData } from '../../types/api';
import { createDefaultListApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Project } from '../../types/project';
import { getProjects, ProjectsAction } from '../actions/projects';

type ProjectsState = ApiListData<Project>;
const initialState: ProjectsState = createDefaultListApiData();

export const projects = (
  state: ProjectsState = initialState,
  action: ProjectsAction
): ProjectsState => {
  switch (action.type) {
    case getType(getProjects.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getProjects.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getType(getProjects.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
