import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Category } from '../../types/category';

export const getCategoryApiRequest = (uuid: string) =>
  createApiAction(
    apiUrl(`categories/${uuid}`),
    '@category/GET_CATEGORY',
    'GET'
  );

export const getCategory = createAsyncAction(
  '@category/GET_CATEGORY_REQUEST',
  '@category/GET_CATEGORY_SUCCESS',
  '@category/GET_CATEGORY_FAILURE'
)<undefined, ApiResponse<Category>, ApiError>();

export const clearCategoryState = createAction(
  '@category/CLEAR_CATEGORY_STATE'
)();

export type CategoryAction = ActionType<
  typeof getCategory | typeof clearCategoryState
>;
