import { ApiError } from '../../types/api';
import { getType } from 'typesafe-actions';
import {
  AuthErrorAction,
  clearAuthError,
  setAuthError,
} from '../actions/authError';

type AuthErrorState = ApiError | null;
const initialState: AuthErrorState = null;

export const authError = (
  state: AuthErrorState = initialState,
  action: AuthErrorAction
): AuthErrorState => {
  switch (action.type) {
    case getType(setAuthError):
      return action.payload;

    case getType(clearAuthError):
      return initialState;

    default:
      return state;
  }
};
