import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { TextField } from '@material-ui/core';
import { FieldProps } from '../../types/form';

export const FormPasswordField = <FormValues extends any>({
  register,
  errors,
  name,
  validationOptions,
  ...props
}: TextFieldProps & FieldProps<FormValues>) => (
  <TextField
    {...props}
    type="password"
    name={name}
    inputRef={validationOptions ? register(validationOptions) : register}
    error={!!errors && !!errors[name]}
    // @ts-ignore
    helperText={errors ? (errors[name] || {}).message : undefined}
  />
);
