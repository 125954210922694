import React, { FC } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { FormTextField } from '../../components/form/FormTextField';
import { useForm } from 'react-hook-form';
import { validationEmail, validationRequired } from '../../services/form/form';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import { createInvitationApiRequest } from '../../store/actions/invitationCreate';
import { FormData } from '../../types/invitation';
import { SubmitFormDialog } from '../../components/form/SubmitFormDialog';

interface Props {
  isOpen: boolean;
  projectUuid: string;
}

const createFormData = (): FormData => ({
  email: '',
});

export const InvitationFormDialog: FC<Props> = ({ isOpen, projectUuid }) => {
  const { closeDialog } = useDialog();

  const { register, errors, handleSubmit } = useForm<FormData>({
    defaultValues: createFormData(),
  });

  const { loading: isSubmitting } = useSelector(
    (state) => state.invitationCreate
  );

  const dispatch = useDispatch();

  const onSubmit = (formData: FormData) => {
    dispatch(createInvitationApiRequest(projectUuid, formData));
  };

  return (
    <SubmitFormDialog
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      isLoading={false}
      title="Invite a team member to the project"
      onSubmit={handleSubmit(onSubmit)}
      onDialogClose={closeDialog}
    >
      <FormTextField
        name="email"
        label="E-mail address"
        register={register}
        errors={errors}
        validationOptions={{ ...validationRequired(), ...validationEmail() }}
        autoFocus
        fullWidth
      />
    </SubmitFormDialog>
  );
};
