import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Category } from '../../types/category';
import {
  clearCategoryState,
  getCategory,
  CategoryAction,
} from '../actions/category';

type CategoryState = ApiSingleData<Category>;
const initialState: CategoryState = createDefaultSingleApiData();

export const category = (
  state: CategoryState = initialState,
  action: CategoryAction
): CategoryState => {
  switch (action.type) {
    case getType(getCategory.request):
      return {
        ...state,
        loading: true,
      };

    case getType(getCategory.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(getCategory.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getType(clearCategoryState):
      return initialState;

    default:
      return state;
  }
};
