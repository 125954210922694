import React, { FC } from 'react';

interface Props {
  scrollableStyle?: 'x' | 'y';
}

export const ScrollableBox: FC<Props> = ({ scrollableStyle, children }) => (
  <div
    className={
      scrollableStyle
        ? scrollableStyle === 'x'
          ? 'scrollable-x'
          : 'scrollable-y'
        : 'scrollable-x scrollable-y'
    }
  >
    {children}
  </div>
);
