import React from 'react';
import { StyledTableRow } from '../table/StyledTableRow';
import { StyledTableCell } from '../table/StyledTableCell';
import { CircularProgress, TableBody } from '@material-ui/core';
import { CenteredText } from '../CenteredText';

interface DataGridLoading {
  isLoading: boolean;
  columnsCount: number;
}

export const DataGridLoading = ({
  isLoading,
  columnsCount,
}: DataGridLoading) => (
  <TableBody className="text-center">
    {isLoading && (
      <StyledTableRow>
        <StyledTableCell colSpan={columnsCount} style={{ padding: '18px 0' }}>
          <CenteredText>
            <CircularProgress size={39} />
          </CenteredText>
        </StyledTableCell>
      </StyledTableRow>
    )}
  </TableBody>
);
