import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Category } from '../../types/category';
import { getCategoriesApiRequest } from './categories';
import { closeDialog } from './dialog';

export const createCategoryApiRequest = (
  projectUuid: string,
  formData: FormData
) =>
  createApiAction(apiUrl('categories'), '@category/CREATE_CATEGORY', 'POST', {
    data: { projectUuid, ...formData },
    onSuccess: [
      () => closeDialog(),
      () => getCategoriesApiRequest(projectUuid),
    ],
  });

export const createCategory = createAsyncAction(
  '@category/CREATE_CATEGORY_REQUEST',
  '@category/CREATE_CATEGORY_SUCCESS',
  '@category/CREATE_CATEGORY_FAILURE'
)<undefined, ApiResponse<Category>, ApiError>();

export type CategoryCreateAction = ActionType<typeof createCategory>;
