import { ApiListData } from '../../types/api';
import { createDefaultListApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Category } from '../../types/category';
import { getCategories, CategoriesAction } from '../actions/categories';

type CategoriesState = ApiListData<Category>;
const initialState: CategoriesState = createDefaultListApiData();

export const categories = (
  state: CategoriesState = initialState,
  action: CategoriesAction
): CategoriesState => {
  switch (action.type) {
    case getType(getCategories.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getCategories.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getType(getCategories.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
