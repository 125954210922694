import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a7aec',
    },
    secondary: {
      main: '#23e0bf',
    },
  },
});
