import React, { useEffect } from 'react';
import { UserProfilePageContainer } from '../../components/layout/UserProfilePageContainer';
import { validationRequired } from '../../services/form/form';
import { useForm } from 'react-hook-form';
import { MIN_PASSWORD_LENGTH } from '../../constants';
import { StyledButton } from '../../components/StyledButton';
import { PasswordChangeFormData } from '../../types/user';
import { useDispatch } from 'react-redux';
import {
  changeUserPasswordApiRequest,
  clearUserPasswordChangeState,
} from '../../store/actions/userPasswordChange';
import { useSelector } from '../../hooks/useSelector';
import { getFirstErrorMessage } from '../../services/api/apiError';
import { SuccessMessage } from '../../components/SuccessMessage';
import { FormPasswordField } from '../../components/form/FormPasswordField';

export const PasswordChangePage = () => {
  const dispatch = useDispatch();

  const {
    loading: isSubmitting,
    data: userPasswordChangeData,
    error,
  } = useSelector((state) => state.userPasswordChange);

  const { register, errors, handleSubmit, watch, reset } = useForm<
    PasswordChangeFormData
  >();

  const errorMessage = getFirstErrorMessage(error);

  const onSubmit = (formData: PasswordChangeFormData) => {
    dispatch(changeUserPasswordApiRequest(formData));
  };

  useEffect(() => {
    if (!!userPasswordChangeData) {
      reset({
        newPassword: '',
        newPasswordVerification: '',
        originalPassword: '',
      });
    }
  }, [reset, userPasswordChangeData]);

  useEffect(() => {
    return () => {
      dispatch(clearUserPasswordChangeState());
    };
  }, [dispatch]);

  return (
    <UserProfilePageContainer activeTab="passwordChange">
      {!!userPasswordChangeData && (
        <div style={{ marginBottom: 32 }}>
          <SuccessMessage>Password successfully changed.</SuccessMessage>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '50%', margin: 'auto' }}
      >
        <FormPasswordField
          name="originalPassword"
          label="Original password"
          register={register}
          errors={{
            ...errors,
            ...(!!errorMessage
              ? {
                  originalPassword: { type: 'required', message: errorMessage },
                }
              : {}),
          }}
          validationOptions={{ ...validationRequired() }}
          style={{ marginBottom: 16 }}
          autoFocus
          fullWidth
        />
        <FormPasswordField
          name="newPassword"
          label="New password"
          register={register}
          errors={errors}
          validationOptions={{
            ...validationRequired(),
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
            },
          }}
          style={{ marginBottom: 16 }}
          fullWidth
        />
        <FormPasswordField
          name="newPasswordVerification"
          label="New password verification"
          register={register}
          errors={errors}
          validationOptions={{
            ...validationRequired(),
            validate: (value) =>
              value === watch('newPassword') ? true : 'Passwords do not match',
          }}
          style={{ marginBottom: 16 }}
          fullWidth
        />

        <StyledButton type="submit" isSubmitting={isSubmitting}>
          Change password
        </StyledButton>
      </form>
    </UserProfilePageContainer>
  );
};
