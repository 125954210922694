import { Action, Dispatch, MiddlewareAPI } from 'redux';
import {
  API_REQUEST_TYPE,
  ApiError,
  ApiErrorMessage,
  ApiPayload,
} from '../../types/api';
import axios from 'axios';
import { authTokenStorage } from '../../services/authToken/authToken';

type DataType = any;

const errorMessage = (context: string, message: string): ApiErrorMessage => ({
  context,
  messages: [message],
});

const commonErrorMessage = (message: string): ApiErrorMessage =>
  errorMessage('common', message);

const createApiError = (
  status?: number,
  messages?: ApiError['messages']
): ApiError => ({
  status,
  messages,
});

const requestAction = (actionPrefix: string): Action => ({
  type: `${actionPrefix}_REQUEST`,
});

const successAction = (actionPrefix: string, data: object) => ({
  type: `${actionPrefix}_SUCCESS`,
  payload: data,
});

const failureAction = (actionPrefix: string, apiError: ApiError) => ({
  type: `${actionPrefix}_FAILURE`,
  payload: apiError,
});

export const apiMiddleware = ({ dispatch }: MiddlewareAPI) => (
  next: Dispatch
) => async (action: any) => {
  next(action);

  if (action.type !== API_REQUEST_TYPE) {
    return;
  }

  const authToken = authTokenStorage.loadToken();

  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const {
    url,
    method = 'GET',
    data,
    onSuccess,
    actionPrefix,
    headers,
    applyAuthToken,
  } = action.payload as ApiPayload<DataType>;

  dispatch(requestAction(actionPrefix));

  const dataProp = method === 'GET' ? 'params' : 'data';

  try {
    const response = await axios.request({
      url: `${process.env.REACT_APP_API_BASE_URL || ''}${url}`,
      method,
      headers: {
        ...headers,
        Authorization:
          authToken && applyAuthToken !== false
            ? `Bearer ${authToken}`
            : undefined,
      },
      [dataProp]: data,
    });

    if (response.data && response.data.data.authToken) {
      authTokenStorage.storeToken(response.data.data.authToken);
    }

    dispatch(successAction(actionPrefix, response.data));

    if (onSuccess) {
      onSuccess.forEach((actionCreator: (data: any) => Action) =>
        dispatch(actionCreator(response.data))
      );
    }
  } catch (error) {
    const status = error.response?.status;

    let apiError: ApiError = createApiError(!!status ? status : undefined, [
      commonErrorMessage(!!status ? 'Server error' : 'Network error'),
    ]);

    if (status === 401) {
      apiError = createApiError(status, [
        commonErrorMessage(
          error.response.data.message
            ? error.response.data.message
            : error.response.data.errors
        ),
      ]);
    }

    if (status === 422) {
      apiError = createApiError(status, error.response.data.errors);
    }

    dispatch(failureAction(actionPrefix, apiError));
  }
};
