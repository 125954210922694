import { DataGridColumn } from '../../types/dataGrid';

export const gridColumn = <T>(
  name: string,
  title: string | JSX.Element,
  customTemplate?: (row: T, index: number) => JSX.Element
): DataGridColumn<T> => ({
  name,
  title,
  customTemplate,
});
