import * as React from 'react';
import { theme } from '../../styles/theme';
import { StyledButton } from '../../components/StyledButton';
import { styled } from '@material-ui/core/styles';
import { FormTextField } from '../../components/form/FormTextField';
import { useForm } from 'react-hook-form';
import { FormData } from '../../types/task';
import { FormSelectField } from '../../components/form/FormSelectField';
import { selectOption, validationRequired } from '../../services/form/form';
import { taskStatusOptions } from './form';
import { useSelector } from '../../hooks/useSelector';
import { FormAutocompleteField } from '../../components/form/FormAutocompleteField';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createTaskApiRequest } from '../../store/actions/taskCreate';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import { useDialog } from '../../hooks/useDialog';
import { FormMultipleAutocompleteField } from '../../components/form/FormMultipleAutocompleteField';

const CreateForm = styled('form')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  alignItems: 'flex-end',
}));

interface Props {
  projectUuid: string;
}

export const TaskCreateForm: FC<Props> = ({ projectUuid }) => {
  const { register, errors, handleSubmit, setValue, reset, watch } = useForm<
    FormData
  >();

  const { data: projectUsers } = useSelector((state) => state.users);
  const { data: categories } = useSelector((state) => state.categories);
  const { data: createTaskData, loading: isSubmitting } = useSelector(
    (state) => state.taskCreate
  );

  const dispatch = useDispatch();

  const { openDialog } = useDialog();

  const onSubmit = (formData: FormData) => {
    dispatch(createTaskApiRequest(projectUuid, formData));
  };

  useEffect(() => {
    if (createTaskData) {
      reset({
        name: '',
        assignedUserUuid: '',
        categoryUuids: [],
        dependency: '',
        description: '',
        status: '',
      });
    }
  }, [createTaskData, reset]);

  return (
    <CreateForm onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        label="Task name"
        name="name"
        register={register}
        validationOptions={{ ...validationRequired() }}
        errors={errors}
        fullWidth
        style={{
          marginRight: theme.spacing(2),
        }}
      />
      <FormMultipleAutocompleteField
        label="Category"
        name="categoryUuids"
        register={register}
        setValue={setValue}
        watch={watch}
        options={(categories || []).map(({ uuid, name }) =>
          selectOption(uuid, name)
        )}
        fullWidth
        style={{
          alignSelf: 'center',
        }}
      />
      <Fab
        color="secondary"
        size="small"
        onClick={() => {
          openDialog('categoryDialog');
        }}
        style={{
          color: '#fff',
          flexShrink: 0,
          marginRight: theme.spacing(2),
        }}
      >
        <AddIcon />
      </Fab>
      <FormAutocompleteField
        name="assignedUserUuid"
        label="Assigned user"
        options={(projectUsers || []).map(({ uuid, name }) =>
          selectOption(uuid, name)
        )}
        watch={watch}
        setValue={setValue}
        register={register}
        fullWidth
        style={{
          marginRight: theme.spacing(2),
        }}
      />
      {/*<FormTextField*/}
      {/*  label="Description"*/}
      {/*  name="description"*/}
      {/*  register={register}*/}
      {/*  errors={errors}*/}
      {/*  fullWidth*/}
      {/*  style={{*/}
      {/*    marginRight: theme.spacing(2),*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<FormTextField*/}
      {/*  label="Dependency"*/}
      {/*  name="dependency"*/}
      {/*  register={register}*/}
      {/*  errors={errors}*/}
      {/*  fullWidth*/}
      {/*  style={{*/}
      {/*    marginRight: theme.spacing(2),*/}
      {/*  }}*/}
      {/*/>*/}
      <FormSelectField
        label="Status"
        name="status"
        register={register}
        setValue={setValue}
        options={taskStatusOptions}
        watch={watch}
        fullWidth
        style={{
          marginRight: theme.spacing(2),
        }}
      />
      <StyledButton type="submit" isSubmitting={isSubmitting} fullWidth>
        Add task
      </StyledButton>
    </CreateForm>
  );
};
