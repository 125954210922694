import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getInvitationsApiRequest } from './invitations';

export const dismissInvitationApiRequest = (uuid: string) =>
  createApiAction(
    apiUrl(`invitations/${uuid}/dismiss`),
    '@invitation/DISMISS_INVITATION',
    'PUT',
    {
      onSuccess: [() => getInvitationsApiRequest()],
    }
  );

export const dismissInvitation = createAsyncAction(
  '@invitation/DISMISS_INVITATION_REQUEST',
  '@invitation/DISMISS_INVITATION_SUCCESS',
  '@invitation/DISMISS_INVITATION_FAILURE'
)<undefined, undefined, ApiError>();

export type InvitationDismissAction = ActionType<typeof dismissInvitation>;
