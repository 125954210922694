import { useDispatch } from 'react-redux';
import { completeTaskApiRequest } from '../../store/actions/taskComplete';
import { useSelector } from '../useSelector';

export const useTaskComplete = (projectUuid: string) => {
  const dispatch = useDispatch();

  const { loading: completeTaskSubmitting } = useSelector(
    (state) => state.taskComplete
  );

  const completeTask = (uuid: string) => {
    if (!completeTaskSubmitting) {
      dispatch(completeTaskApiRequest(uuid, projectUuid));
    }
  };

  return {
    completeTask,
  };
};
