import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from '@material-ui/icons';
import { Task } from '../../../types/task';
import { FC } from 'react';

interface Props {
  task: Task;
  toggleTaskHighPriority: (taskUuid: string) => void;
}

export const StarTaskIcon: FC<Props> = ({ task, toggleTaskHighPriority }) => (
  <>
    {task.priority === 0 && (
      <Tooltip title="Star task">
        <StarBorderIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => toggleTaskHighPriority(task.uuid)}
        />
      </Tooltip>
    )}
    {task.priority === 1 && (
      <Tooltip title="Unstar task">
        <StarIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => toggleTaskHighPriority(task.uuid)}
        />
      </Tooltip>
    )}
  </>
);
