import React, { FC } from 'react';
import { styled } from '@material-ui/core';
import { Anchor } from '../Anchor';
import { theme } from '../../styles/theme';

const Wrapper = styled('div')(() => ({
  textAlign: 'center',
  bottom: 0,
  padding: `${theme.spacing(3)}px 0`,
  width: '100%',
}));

export const Footer: FC = () => (
  <Wrapper>
    Flowlify &copy; 2020{' '}
    <Anchor href="mailto:martin@martyhora.cz">Martin Hora</Anchor>
  </Wrapper>
);
