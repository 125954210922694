import React, { FC } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Task } from '../../types/task';

interface Props {
  task: Task;
  unlockDependency: (taskUuid: string) => void;
}

export const TaskDependency: FC<Props> = ({ task, unlockDependency }) =>
  !!task.dependency ? (
    <Grid container alignItems="center">
      <Tooltip title="Unlock dependency">
        <LockIcon
          style={{
            color: red[500],
            marginRight: 4,
            cursor: 'pointer',
          }}
          onClick={() => {
            unlockDependency(task.uuid);
          }}
        />
      </Tooltip>
      {task.dependency}
    </Grid>
  ) : null;
