import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { MultipleAutocompleteFieldProps, SelectOption } from '../../types/form';
import { UseAutocompleteProps } from '@material-ui/lab/useAutocomplete';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';
import { usePrevious } from '../../library/hooks/usePrevious';

export const FormMultipleAutocompleteField = <FormValues extends any>({
  register,
  name,
  validationOptions,
  label,
  options,
  setValue,
  initialValue,
  watch,
  ...props
}: Omit<
  AutocompleteProps<SelectOption> & UseAutocompleteProps<SelectOption>,
  'renderInput'
> &
  MultipleAutocompleteFieldProps<FormValues>) => {
  const [currentValue, setCurrentValue] = useState<SelectOption[]>([]);

  const value = watch(name);

  const previousInitialValue = usePrevious(initialValue);

  const serializedOptions = JSON.stringify(options);
  const serializedInitialValue = JSON.stringify(initialValue);
  const serializedPreviousInitialValue = JSON.stringify(previousInitialValue);

  const getOptions = useCallback(
    (keys?: SelectOption['key'][]) =>
      options.filter((option) => (keys || []).includes(option.key)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serializedOptions]
  );

  const changeValue = useCallback(
    (value: SelectOption[]) => {
      setCurrentValue(value);
      setValue(
        name,
        value.map((value) => value.key)
      );
    },
    [name, setValue]
  );

  useEffect(() => {
    // @ts-ignore
    register(name, validationOptions);
  }, [name, register, validationOptions]);

  useEffect(() => {
    if (previousInitialValue !== initialValue) {
      changeValue(getOptions(initialValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serializedInitialValue,
    serializedPreviousInitialValue,
    getOptions,
    changeValue,
  ]);

  useEffect(() => {
    if (value !== undefined) {
      setCurrentValue(getOptions(value));
    }
  }, [getOptions, value]);

  const autocomplete = (
    // @ts-ignore
    <Autocomplete
      {...props}
      value={currentValue}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.key === value.key}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(event: any, newValue: SelectOption[]) => {
        changeValue(newValue);
      }}
      fullWidth
      multiple
    />
  );

  return autocomplete;
};
