import React, { FC } from 'react';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Task } from '../../../types/task';

interface Props {
  task: Task;
  onTaskRemove: (taskUuid: string) => void;
}

export const DeleteTaskIcon: FC<Props> = ({ task, onTaskRemove }) => (
  <Tooltip title="Remove task">
    <DeleteIcon
      color={'primary'}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        onTaskRemove(task.uuid);
      }}
    />
  </Tooltip>
);
