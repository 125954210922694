import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import { clearTaskState, getTask, TaskAction } from '../actions/task';

type TaskState = ApiSingleData<Task>;
const initialState: TaskState = createDefaultSingleApiData();

export const task = (
  state: TaskState = initialState,
  action: TaskAction
): TaskState => {
  switch (action.type) {
    case getType(getTask.request):
      return {
        ...state,
        loading: true,
      };

    case getType(getTask.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(getTask.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case getType(clearTaskState):
      return initialState;

    default:
      return state;
  }
};
