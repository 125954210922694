import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectPageContainer } from '../../components/layout/ProjectPageContainer';
import { getTaskApiRequest } from '../../store/actions/task';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from '../../hooks/useSelector';
import Typography from '@material-ui/core/Typography';
import { CategoryBadgeList } from '../../components/category/CategoryBadgeList';
import { Box, CircularProgress } from '@material-ui/core';
import { TaskStatusBadge } from '../tasks/TaskStatusBadge';
import Paper from '@material-ui/core/Paper';
import { TaskActions } from '../../components/task/TaskActions';
import { useTaskRemove } from '../../hooks/task/useTaskRemove';
import { useTaskComplete } from '../../hooks/task/useTaskComplete';
import { useTaskTogglePriority } from '../../hooks/task/useTaskTogglePriority';
import { CenteredText } from '../../components/CenteredText';
import { StyledButton } from '../../components/StyledButton';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import { FormData } from '../../types/task';
import { updateTaskApiRequest } from '../../store/actions/taskUpdate';
import { useForm } from 'react-hook-form';
import { createApiPayload, createFormData } from '../../utils/task/taskForm';
import { selectOption, validationRequired } from '../../services/form/form';
import { FormTextField } from '../../components/form/FormTextField';
import { FormSelectField } from '../../components/form/FormSelectField';
import { taskStatusOptions } from '../tasks/form';
import { FormMultipleAutocompleteField } from '../../components/form/FormMultipleAutocompleteField';
import { getCategoriesApiRequest } from '../../store/actions/categories';
import { routes } from '../../routes/routes';
import { AlertDialog } from '../../AlertDialog';
import { useDialog } from '../../hooks/useDialog';
import { BorderColor as InlineEditIcon } from '@material-ui/icons';

export const TaskDetailPage = () => {
  const { register, errors, handleSubmit, setValue, watch } = useForm<
    FormData
  >();

  const dispatch = useDispatch();

  const { isDialogOpen } = useDialog();

  const { push } = useHistory();

  const { uuid, projectUuid } = useParams<{
    uuid: string;
    projectUuid: string;
  }>();

  const [isEdit, setIsEdit] = useState(false);

  const { data: task, loading: taskLoading } = useSelector(
    (state) => state.task
  );

  const { data: categories } = useSelector((state) => state.categories);

  const { loading: isSubmitting } = useSelector((state) => state.taskUpdate);

  const editTask = () => {
    setIsEdit(true);
  };

  const { onTaskRemove, closeRemoveDialog, removeTask } = useTaskRemove(
    projectUuid
  );
  const { completeTask } = useTaskComplete(projectUuid);
  const { toggleTaskHighPriority } = useTaskTogglePriority(projectUuid);

  const onRemoveTaskProceed = () => {
    removeTask();
    push(routes.tasks(projectUuid));
  };

  const onSubmit = (formData: FormData) => {
    dispatch(
      updateTaskApiRequest(task!.uuid, projectUuid, createApiPayload(formData))
    );
    setIsEdit(false);
  };

  const setValues = useCallback(
    (formData: FormData) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    },
    [setValue]
  );

  useEffect(() => {
    if (task) {
      setValues(createFormData(task));
    }
    console.log(task);
  }, [task, setValues]);

  useEffect(() => {
    dispatch(getTaskApiRequest(uuid));
    dispatch(getCategoriesApiRequest(projectUuid));
  }, [uuid, dispatch, projectUuid]);
  // console.log(task);
  const formElementDisplay = { display: isEdit ? 'block' : 'none' };

  return (
    <ProjectPageContainer activeTab="tasks">
      {taskLoading && !task && (
        <CenteredText>
          <CircularProgress size={39} />
        </CenteredText>
      )}

      {!!task && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 36,
              }}
            >
              <Typography variant="h6" component="h6">
                <FormTextField
                  label="Task name"
                  name="name"
                  register={register}
                  validationOptions={{ ...validationRequired() }}
                  errors={errors}
                  fullWidth
                  autoFocus
                  style={{ width: 500, ...formElementDisplay }}
                />

                {!isEdit && <>{task.name}</>}
              </Typography>

              <div
                style={{
                  width: isEdit ? 144 : task.status === 'done' ? 100 : 120,
                }}
              >
                {!isEdit ? (
                  <TaskActions
                    task={task}
                    selectedTaskUuid={null}
                    selectedTaskLoading={false}
                    editTask={editTask}
                    toggleTaskHighPriority={toggleTaskHighPriority}
                    onTaskRemove={onTaskRemove}
                    completeTask={completeTask}
                  />
                ) : (
                  <>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      style={{ color: '#fff', marginRight: 16 }}
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                    >
                      <SaveIcon />
                    </StyledButton>
                    <Button
                      variant="contained"
                      color="default"
                      style={{ color: '#fff' }}
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      <CancelIcon />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Box>

          <Paper style={{ padding: 16 }}>
            <Box mb={3}>
              <table className="table">
                <tbody>
                  <tr>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingBottom: 8,
                        paddingRight: 8,
                        verticalAlign: 'top',
                      }}
                    >
                      Status:
                    </th>
                    <td style={{ textAlign: 'left', paddingBottom: 8 }}>
                      <FormSelectField
                        label="Status"
                        name="status"
                        register={register}
                        setValue={setValue}
                        options={taskStatusOptions.filter(
                          (option) => !!option.key
                        )}
                        defaultValue={task?.status || ''}
                        style={{ width: 150, ...formElementDisplay }}
                        watch={watch}
                        fullWidth
                      />

                      {!isEdit && <TaskStatusBadge status={task.status} />}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingRight: 8,
                        verticalAlign: 'top',
                      }}
                    >
                      Category:
                    </th>
                    <td style={{ textAlign: 'left' }}>
                      <div style={formElementDisplay}>
                        <FormMultipleAutocompleteField
                          label="Category"
                          name="categoryUuids"
                          register={register}
                          setValue={setValue}
                          initialValue={
                            task.categories.map((c) => c.uuid) || []
                          }
                          options={(categories || []).map(({ uuid, name }) =>
                            selectOption(uuid, name)
                          )}
                          watch={watch}
                          fullWidth
                        />
                        {/*<Fab*/}
                        {/*  color="secondary"*/}
                        {/*  size="small"*/}
                        {/*  onClick={() => {*/}
                        {/*    openDialog('categoryDialog');*/}
                        {/*  }}*/}
                        {/*  style={{*/}
                        {/*    color: '#fff',*/}
                        {/*    flexShrink: 0,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  <AddIcon />*/}
                        {/*</Fab>*/}
                      </div>

                      {!isEdit && (
                        <CategoryBadgeList
                          categories={task.categories}
                          displayStyle={'row'}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <>
              <Box mb={3}>
                <Typography variant="h6" component="h6">
                  Description
                </Typography>
              </Box>

              <FormTextField
                label="Description"
                name="description"
                register={register}
                errors={errors}
                rows={4}
                style={formElementDisplay}
                multiline
                fullWidth
              />

              {!!task.description && !isEdit && <>{task.description}</>}

              {!task.description && !isEdit && (
                <InlineEditIcon
                  color={'primary'}
                  style={{
                    cursor: 'pointer',
                    marginRight: 8,
                  }}
                  onClick={editTask}
                />
              )}
            </>
          </Paper>
        </form>
      )}

      <AlertDialog
        title="Remove task"
        text="Do you really want to remove selected task?"
        onDialogClose={closeRemoveDialog}
        isOpen={isDialogOpen('removeTaskDialog')}
        onProceed={onRemoveTaskProceed}
      />
    </ProjectPageContainer>
  );
};
