import { ApiListData } from '../../types/api';
import { createDefaultListApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Invitation } from '../../types/invitation';
import { getInvitations, InvitationsAction } from '../actions/invitations';

type InvitationsState = ApiListData<Invitation>;
const initialState: InvitationsState = createDefaultListApiData();

export const invitations = (
  state: InvitationsState = initialState,
  action: InvitationsAction
): InvitationsState => {
  switch (action.type) {
    case getType(getInvitations.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getInvitations.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };
    case getType(getInvitations.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
