import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Invitation } from '../../types/invitation';

export const getInvitationsApiRequest = () =>
  createApiAction(
    apiUrl('invitations'),
    '@invitations/GET_INVITATIONS',
    'GET',
    { data: { status: 'pending' } }
  );

export const getInvitations = createAsyncAction(
  '@invitations/GET_INVITATIONS_REQUEST',
  '@invitations/GET_INVITATIONS_SUCCESS',
  '@invitations/GET_INVITATIONS_FAILURE'
)<undefined, ApiResponse<Invitation[]>, ApiError>();

export type InvitationsAction = ActionType<typeof getInvitations>;
