import React, { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface Props {
  isSubmitting?: boolean;
}

export const StyledButton: FC<ButtonProps & Props> = ({
  children,
  isSubmitting,
  ...props
}) => {
  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      disabled={isSubmitting}
      style={{
        color: '#fff',
        textDecoration: 'none',
        ...(props.style ? props.style : {}),
      }}
    >
      {isSubmitting ? (
        <CircularProgress size={24} style={{ color: '#fff' }} />
      ) : (
        children
      )}
    </Button>
  );
};
