import React, { FC, useCallback, useEffect } from 'react';
import { StyledTableRow } from '../../components/table/StyledTableRow';
import { StyledTableCell } from '../../components/table/StyledTableCell';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { FormTextField } from '../../components/form/FormTextField';
import { selectOption, validationRequired } from '../../services/form/form';
import { useForm } from 'react-hook-form';
import { FormData, Task } from '../../types/task';
import { FormSelectField } from '../../components/form/FormSelectField';
import { useSelector } from '../../hooks/useSelector';
import { useDispatch } from 'react-redux';
import { useDialog } from '../../hooks/useDialog';
import { updateTaskApiRequest } from '../../store/actions/taskUpdate';
import { FormAutocompleteField } from '../../components/form/FormAutocompleteField';
import { taskStatusOptions } from './form';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { StyledButton } from '../../components/StyledButton';
import { FormMultipleAutocompleteField } from '../../components/form/FormMultipleAutocompleteField';
import { withStyles } from '@material-ui/core/styles';
import { createFormData } from '../../utils/task/taskForm';

export const InlineFormTableCell = withStyles(() => ({
  body: {
    verticalAlign: 'bottom',
  },
}))(StyledTableCell);

interface Props {
  projectUuid: string;
  task: Task;
  onCancel: () => void;
}

export const TaskInlineUpdateForm: FC<Props> = ({
  projectUuid,
  task,
  onCancel,
}) => {
  const { register, errors, handleSubmit, setValue, watch } = useForm<
    FormData
  >();

  const { data: projectUsers } = useSelector((state) => state.users);
  const { data: categories } = useSelector((state) => state.categories);
  const { loading: isSubmitting } = useSelector((state) => state.taskUpdate);

  const dispatch = useDispatch();

  const { openDialog } = useDialog();

  const onSubmit = (formData: FormData) => {
    dispatch(updateTaskApiRequest(task!.uuid, projectUuid, formData));
  };

  const setValues = useCallback(
    (formData: FormData) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    },
    [setValue]
  );

  useEffect(() => {
    if (task) {
      setValues(createFormData(task));
    }
  }, [task, setValues]);

  return (
    <StyledTableRow>
      <InlineFormTableCell>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            label="Task name"
            name="name"
            register={register}
            validationOptions={{ ...validationRequired() }}
            errors={errors}
            fullWidth
            autoFocus
          />
        </form>
      </InlineFormTableCell>
      <InlineFormTableCell>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <FormMultipleAutocompleteField
            label="Category"
            name="categoryUuids"
            register={register}
            setValue={setValue}
            initialValue={task?.categories.map((c) => c.uuid) || []}
            options={(categories || []).map(({ uuid, name }) =>
              selectOption(uuid, name)
            )}
            watch={watch}
            fullWidth
          />
          <Fab
            color="secondary"
            size="small"
            onClick={() => {
              openDialog('categoryDialog');
            }}
            style={{
              color: '#fff',
              flexShrink: 0,
            }}
          >
            <AddIcon />
          </Fab>
        </form>
      </InlineFormTableCell>
      <InlineFormTableCell>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormAutocompleteField
            name="assignedUserUuid"
            label="Assigned user"
            options={(projectUsers || []).map(({ uuid, name }) =>
              selectOption(uuid, name)
            )}
            setValue={setValue}
            register={register}
            initialValue={task?.assignedUser?.uuid || ''}
            watch={watch}
            fullWidth
          />
        </form>
      </InlineFormTableCell>
      {/*<InlineFormTableCell>*/}
      {/*  <form onSubmit={handleSubmit(onSubmit)}>*/}
      {/*    <FormTextField*/}
      {/*      label="Description"*/}
      {/*      name="description"*/}
      {/*      register={register}*/}
      {/*      errors={errors}*/}
      {/*      fullWidth*/}
      {/*    />*/}
      {/*  </form>*/}
      {/*</InlineFormTableCell>*/}
      <InlineFormTableCell>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            label="Dependency"
            name="dependency"
            register={register}
            errors={errors}
            fullWidth
          />
        </form>
      </InlineFormTableCell>
      <InlineFormTableCell>
        <FormSelectField
          label="Status"
          name="status"
          register={register}
          setValue={setValue}
          options={taskStatusOptions.filter((option) => !!option.key)}
          defaultValue={task?.status || ''}
          style={{ width: 150 }}
          watch={watch}
          fullWidth
        />
      </InlineFormTableCell>
      <InlineFormTableCell align="right">
        <StyledButton
          variant="contained"
          color="secondary"
          style={{ color: '#fff', marginRight: 16 }}
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
        >
          <SaveIcon />
        </StyledButton>
        <Button
          variant="contained"
          color="default"
          style={{ color: '#fff' }}
          onClick={onCancel}
        >
          <CancelIcon />
        </Button>
      </InlineFormTableCell>
    </StyledTableRow>
  );
};
