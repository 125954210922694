import React from 'react';

import { gridColumn } from '../../services/dataGrid/dataGrid';
import { DataGridColumn } from '../../types/dataGrid';
import { Category } from '../../types/category';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { StyledAvatar } from '../../components/StyledAvatar';
import { Tooltip } from '@material-ui/core';

export const createCategoriesGrid = (
  onCategoryEdit: (uuid: string) => void,
  onCategoryRemove: (uuid: string) => void
): DataGridColumn<Category>[] => [
  gridColumn('name', 'Name'),
  gridColumn('color', 'Color', (row) => (
    <StyledAvatar color={row.color} style={{ width: 28, height: 28 }}>
      {' '}
    </StyledAvatar>
  )),
  gridColumn('description', 'Description'),
  gridColumn('actions', 'Actions', (row) => (
    <>
      <Tooltip title="Edit category">
        <EditIcon
          color="primary"
          style={{
            cursor: 'pointer',
            marginRight: 8,
          }}
          onClick={() => {
            onCategoryEdit(row.uuid);
          }}
        />
      </Tooltip>
      <Tooltip title="Remove category">
        <DeleteIcon
          color="primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            onCategoryRemove(row.uuid);
          }}
        />
      </Tooltip>
    </>
  )),
];
