import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, AppBar } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { useUser } from '../../hooks/useUser';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from '../../routes/routes';
import { UserMenu } from './UserMenu';
import whiteLogo from '../../assets/images/logo96.png';

const AppBarWrapper = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const LogoImg = styled('img')(({ theme }) => ({
  width: 24,
  marginRight: theme.spacing(1),
}));

export const StyledAppBar = () => {
  const { isAuthenticated } = useUser();

  const dispatch = useDispatch();

  return (
    <AppBarWrapper position="fixed" color="primary">
      <Toolbar>
        <LogoImg src={whiteLogo} alt="" />
        <Typography
          variant="h6"
          noWrap
          style={{ flexGrow: 1, cursor: 'pointer' }}
          onClick={() => {
            dispatch(push(routes.homepage));
          }}
        >
          {process.env.REACT_APP_APP_NAME}
        </Typography>
        {isAuthenticated && (
          <>
            <Button
              color="inherit"
              onClick={() => {
                dispatch(push(routes.homepage));
              }}
            >
              Projects
            </Button>
            <UserMenu />
          </>
        )}
      </Toolbar>
    </AppBarWrapper>
  );
};
