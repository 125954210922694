import { ApiSingleData } from '../../types/api';
import { createDefaultSingleApiData } from '../../services/api/apiData';
import { getType } from 'typesafe-actions';
import { Task } from '../../types/task';
import {
  TaskToggleHighPriorityAction,
  toggleTaskHighPriority,
} from '../actions/taskToggleHighPriority';

type TaskToggleHighPriorityState = ApiSingleData<Task>;
const initialState: TaskToggleHighPriorityState = createDefaultSingleApiData();

export const taskToggleHighPriority = (
  state: TaskToggleHighPriorityState = initialState,
  action: TaskToggleHighPriorityAction
): TaskToggleHighPriorityState => {
  switch (action.type) {
    case getType(toggleTaskHighPriority.request):
      return {
        ...state,
        loading: true,
      };

    case getType(toggleTaskHighPriority.success):
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null,
      };

    case getType(toggleTaskHighPriority.failure):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
