export const routes = {
  login: '/login',
  signup: '/sign-up',
  homepage: '/',
  userProfile: '/user-profile',
  passwordChange: '/password-change',
  taskDetail: (projectUuid: string, uuid: string) =>
    `/task/${projectUuid}/${uuid}`,
  tasks: (projectUuid: string) => `/tasks/${projectUuid}`,
  categories: (projectUuid: string) => `/categories/${projectUuid}`,
};

const publicRoutes = [routes.login, routes.signup];

export const isPublicRoute = () =>
  publicRoutes.includes(window.location.pathname);
