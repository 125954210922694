import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { StyledButton } from './components/StyledButton';

interface Props {
  text: string;
  title: string;
  isOpen: boolean;
  onDialogClose: () => void;
  onProceed: () => void;
}

export const AlertDialog: FC<Props> = ({
  text,
  title,
  isOpen,
  onDialogClose,
  onProceed,
}) => (
  <Dialog open={isOpen} onClose={onDialogClose}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <StyledButton onClick={onDialogClose}>Cancel</StyledButton>
      <StyledButton onClick={onProceed} autoFocus>
        OK
      </StyledButton>
    </DialogActions>
  </Dialog>
);
