import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { FormData, Project } from '../../types/project';
import { getProjectsApiRequest } from './projects';
import { closeDialog } from './dialog';

export const updateProjectApiRequest = (uuid: string, formData: FormData) =>
  createApiAction(
    apiUrl(`projects/${uuid}`),
    '@project/UPDATE_PROJECT',
    'PUT',
    {
      data: formData,
      onSuccess: [() => closeDialog(), () => getProjectsApiRequest()],
    }
  );

export const updateProject = createAsyncAction(
  '@project/UPDATE_PROJECT_REQUEST',
  '@project/UPDATE_PROJECT_SUCCESS',
  '@project/UPDATE_PROJECT_FAILURE'
)<undefined, ApiResponse<Project>, ApiError>();

export type ProjectUpdateAction = ActionType<typeof updateProject>;
