import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDialog } from '../useDialog';
import { removeTaskApiRequest } from '../../store/actions/taskRemove';

export const useTaskRemove = (projectUuid: string) => {
  const [removeTaskUuid, setRemoveTaskUuid] = useState<string | null>(null);

  const { openDialog, closeDialog } = useDialog();

  const dispatch = useDispatch();

  const closeRemoveDialog = () => {
    closeDialog();
    setRemoveTaskUuid(null);
  };

  const onTaskRemove = (uuid: string) => {
    setRemoveTaskUuid(uuid);
    openDialog('removeTaskDialog');
  };

  const removeTask = () => {
    dispatch(removeTaskApiRequest(removeTaskUuid!, projectUuid));
    closeRemoveDialog();
  };

  return {
    onTaskRemove,
    removeTask,
    closeRemoveDialog,
  };
};
