import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { Category } from '../../types/category';

export const getCategoriesApiRequest = (projectUuid: string) =>
  createApiAction(
    apiUrl(`projects/${projectUuid}/categories`),
    '@categories/GET_PROJECTS',
    'GET'
  );

export const getCategories = createAsyncAction(
  '@categories/GET_PROJECTS_REQUEST',
  '@categories/GET_PROJECTS_SUCCESS',
  '@categories/GET_PROJECTS_FAILURE'
)<undefined, ApiResponse<Category[]>, ApiError>();

export type CategoriesAction = ActionType<typeof getCategories>;
