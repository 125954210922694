import React, { FC, useCallback, useEffect } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { FormTextField } from '../../components/form/FormTextField';
import { useForm } from 'react-hook-form';
import { validationRequired } from '../../services/form/form';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks/useSelector';
import { createProjectApiRequest } from '../../store/actions/projectCreate';
import { FormData, Project } from '../../types/project';
import { updateProjectApiRequest } from '../../store/actions/projectUpdate';
import { clearProjectState } from '../../store/actions/project';
import { useUser } from '../../hooks/useUser';
import { getCompanyUsersApiRequest } from '../../store/actions/users';
import { SubmitFormDialog } from '../../components/form/SubmitFormDialog';

interface Props {
  isOpen: boolean;
  project?: Project;
  projectLoading?: boolean;
}

const createFormData = (project?: Project): FormData => ({
  name: project?.name || '',
  assignedUsers: project?.users ? project.users.map((user) => user.uuid) : [],
});

const createApiPayload = (formData: FormData): FormData => ({
  ...formData,
  assignedUsers: (Array.isArray(formData.assignedUsers)
    ? formData.assignedUsers
    : [formData.assignedUsers]
  ).filter((user) => !!user),
});

export const ProjectFormDialog: FC<Props> = ({
  project,
  projectLoading,
  isOpen,
}) => {
  const { closeDialog } = useDialog();

  const { register, errors, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: createFormData(),
  });

  const { user: currentUser } = useUser();

  const { loading: isCreateSubmitting } = useSelector(
    (state) => state.projectCreate
  );
  const { loading: isUpdateSubmitting } = useSelector(
    (state) => state.projectUpdate
  );

  const dispatch = useDispatch();

  const setValues = useCallback(
    (formData: FormData) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    },
    [setValue]
  );

  const onSubmit = (formData: FormData) => {
    const apiPayload = createApiPayload(formData);

    !!project
      ? dispatch(updateProjectApiRequest(project.uuid, apiPayload))
      : dispatch(createProjectApiRequest(apiPayload));
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getCompanyUsersApiRequest(currentUser!.company.uuid));
    } else {
      dispatch(clearProjectState());
    }
  }, [isOpen, dispatch, currentUser]);

  useEffect(() => {
    if (project) {
      setValues(createFormData(project));
    }
  }, [project, setValues]);

  return (
    <SubmitFormDialog
      isOpen={isOpen}
      isSubmitting={isCreateSubmitting || isUpdateSubmitting}
      isLoading={projectLoading}
      title={
        !!project || projectLoading ? 'Update project' : 'Create new project'
      }
      onSubmit={handleSubmit(onSubmit)}
      onDialogClose={closeDialog}
    >
      <FormTextField
        name="name"
        label="Project name"
        register={register}
        errors={errors}
        validationOptions={{ ...validationRequired() }}
        autoFocus
        fullWidth
      />
    </SubmitFormDialog>
  );
};
