import React from 'react';
import { DataGridColumn } from '../../types/dataGrid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell } from '../table/StyledTableCell';

interface Props<T> {
  columns: Array<DataGridColumn<T>>;
}

export const DataGridHeader = <T extends any>({ columns }: Props<T>) => (
  <TableHead>
    <TableRow>
      {columns.map((column, i) => (
        <StyledTableCell
          key={i}
          style={{ textAlign: i === columns.length - 1 ? 'right' : 'left' }}
        >
          {column.title}
        </StyledTableCell>
      ))}
    </TableRow>
  </TableHead>
);
