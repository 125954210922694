import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ApiError, ApiResponse } from '../../types/api';
import { createApiAction } from '../../services/api/apiAction';
import { apiUrl } from '../../services/api/apiUrl';
import { getTasksApiRequest } from './tasks';
import { Task } from '../../types/task';

export const unlockTaskDependencyApiRequest = (
  uuid: string,
  projectUuid: string
) =>
  createApiAction(
    apiUrl(`tasks/${uuid}/dependency-unlock`),
    '@task/UNLOCK_DEPENDENCY',
    'PUT',
    {
      onSuccess: [() => getTasksApiRequest(projectUuid)],
    }
  );

export const unlockTaskDependency = createAsyncAction(
  '@task/UNLOCK_DEPENDENCY_REQUEST',
  '@task/UNLOCK_DEPENDENCY_SUCCESS',
  '@task/UNLOCK_DEPENDENCY_FAILURE'
)<undefined, ApiResponse<Task>, ApiError>();

export type TaskDependencyUnlockAction = ActionType<
  typeof unlockTaskDependency
>;
